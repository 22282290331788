import React, { useState } from "react";
import { Input, SingleClick } from "../../../micros";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function StepOne({ onNext, closePopup, formData, updateFormData }) {
  const [errors, setErrors] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [yearError, setYearError] = useState("");
  const [checkboxOneChecked, setCheckboxOneChecked] = useState(false);
  const [checkboxTwoChecked, setCheckboxTwoChecked] = useState(false);
  const {
    full_name,
    student_email,
    phone_number,
    registration_number,
    course_enrolled,
    institution,
    faculty_school,
    department,
    year_enrolled,
    graduation_year,
  } = formData;

  // Handle input change
  const handleChange = (id, value) => {
    updateFormData({ [id]: value });
    setErrors("");
    // validatePhoneNumber(formData.phone_number);
  };

  // Validate phone number
  const validatePhoneNumber = (value) => {
    // Phone number must be between 10-13 characters long including the country code
    if (value?.length >= 10 && value?.length <= 13) {
      setPhoneError("");
    } else {
      setPhoneError(
        "Phone number must be between 10 and 13 characters long, including the country code."
      );
    }
  };
  // year validation
  const validateYear = (value) => {
    let validRegex = /^[0-9]{4}$/;
    if (!validRegex.test(value)) {
      setYearError("Please enter a valid year (e.g., 2023).");
      return true;
    }
    setYearError("");
    return false;
  };
  // const handleInput = () => {
  // validateYear(year_enrolled);
  // validateYear(graduation_year);
  // };
  const handlePhoneInput = () => {
    // validatePhoneNumber(formData.phone_number);
  };

  // save data and move to next steps
  const saveData = () => {
    console.log(formData);

    let newErrors = [];
    if (year_enrolled > graduation_year) {
      newErrors.push(
        "Graduation year must be greater than or equal to the year of enrollment."
      );
    }
    if (
      full_name == "" ||
      student_email == "" ||
      phone_number == "" ||
      registration_number == "" ||
      course_enrolled == "" ||
      institution == "" ||
      faculty_school == "" ||
      department == "" ||
      year_enrolled == "" ||
      graduation_year == ""
    ) {
      // setError("Please fill in all the required fields.");
      // return;
      newErrors.push("Please fill in all the required fields.");
    }
    if (registration_number !== registration_number.toUpperCase()) {
      // setError("Registration Number must be in uppercase.");
      newErrors.push("Registration Number must be in uppercase.");
      // return;
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    if (phoneError) {
      return;
    }
    if (yearError) {
      return;
    }
    validatePhoneNumber(formData.phone_number);
    setErrors([]);
    onNext();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveData();
  };

  const handleCheckboxOneChange = (value) => {
    setCheckboxOneChecked(value);
  };

  const handleCheckboxTwoChange = (value) => {
    setCheckboxTwoChecked(value);
  };

  return (
    <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[] md:w-[25rem] md:h-[] h-auto md:max-w-[28rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto">
      <div className="relative font-poppins pb-6">
        <p
          className="absolute flex justify-center items-center cursor-pointer  right-0 top-0  rounded-full text-center z-100"
          onClick={closePopup}
        >
          {/* <img src="/cancel.svg" alt="" className="size-4" /> */}
          <img src="/close.svg" alt="" className="size-4" />
        </p>
        <div className="px-6 pt-10">
          <h2 className="text-2xl text-gray-800 font-poppins-semi-bold mb-3">
            1. Step One
          </h2>
          {/* Registerartion form */}
          <form action="" className="" onSubmit={(e) => handleSubmit(e)}>
            <h2 className="text-xl font-poppins-semi-bold text-[#05195F] mb-2">
              Complete your Information
            </h2>

            <label htmlFor="fullname">
              Full Names <span className="text-red-900">*</span>
            </label>
            {/* <div className="mb-4 h"> */}
            <Input
              type="text"
              id="full_name"
              className=""
              inputClass="5"
              // placeholder="Enter your full names as they appear in students ID"
              value={formData.full_name}
              onChange={(value) => handleChange("full_name", value)}
            />
            {/* </div> */}
            <label htmlFor="email">
              Student Email <span className="text-red-900">*</span>
            </label>
            <Input
              type="studentemail"
              id="student_email"
              inputClass="5"
              // isRequired={true}
              placeholder="Enter your students email"
              value={formData.student_email}
              onChange={(value) => handleChange("student_email", value)}
            />
            <label htmlFor="phone">
              Phone No. <span className="text-red-900">*</span>
            </label>
            {/* <Input
              type="text"
              id="phone_number"
              inputClass="5"
              placeholder="Enter your active phone number"
              value={formData.phone_number}
              onChange={(value) => handleChange("phone_number", value)}
            /> */}
            <PhoneInput
              international
              defaultCountry="KE"
              value={formData.phone_number}
              onChange={(value) => handleChange("phone_number", value)}
              className="border py-3 mb-3 px-4 w-full rounded-md border-purple-darker border-opacity-10 focus:outline-none "
              style={{ backgroundColor: "#FFF5FE" }}
              onInput={handlePhoneInput}
              required
            />
            {phoneError && (
              <p className="text-red-500 text-xs mb-3">{phoneError}</p>
            )}
            <label htmlFor="regno">
              Registeration No. <span className="text-red-900">*</span>
            </label>
            <Input
              type="textalpha"
              id="registration_number"
              inputClass="5"
              placeholder="Enter your registration number in Caps"
              value={formData.registration_number}
              onChange={(value) => handleChange("registration_number", value)}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
            />
            <label htmlFor="course">
              Course Enrolled <span className="text-red-900">*</span>
            </label>
            <Input
              type="text"
              id="course_enrolled"
              inputClass="5"
              placeholder="Enter your course of name"
              value={formData.course_enrolled}
              onChange={(value) => handleChange("course_enrolled", value)}
            />
            <label htmlFor="institution">
              Name of Institution <span className="text-red-900">*</span>
            </label>
            <Input
              type="text"
              id="institution"
              inputClass="5"
              placeholder="Enter name of your institution"
              value={formData.institution}
              onChange={(value) => handleChange("institution", value)}
            />
            <label htmlFor="faculty">
              Faculty/School <span className="text-red-900">*</span>
            </label>
            <Input
              type="text"
              id="faculty_school"
              inputClass="5"
              className="text-lg"
              placeholder="Enter name of your faculty/school, don't use abbreviations"
              value={formData.faculty_school}
              onChange={(value) => handleChange("faculty_school", value)}
            />
            <label htmlFor="department">
              Department <span className="text-red-900">*</span>
            </label>
            <Input
              type="text"
              id="department"
              inputClass="5"
              placeholder="Enter name of your department"
              value={formData.department}
              onChange={(value) => handleChange("department", value)}
            />
            <div className="flex flex-col sm:flex-row gap-3">
              <div>
                <label htmlFor="enrolled">
                  Year Enrolled <span className="text-red-900">*</span>
                </label>
                <Input
                  type="year"
                  id="year_enrolled"
                  inputClass="5"
                  placeholder="Enter date of enrollment"
                  value={formData.year_enrolled}
                  onChange={(value) => handleChange("year_enrolled", value)}
                  onInput={(e) => validateYear(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="graduation">
                  Graduation Year <span className="text-red-900">*</span>
                </label>
                <Input
                  type="year"
                  id="graduation_year"
                  inputClass="5"
                  className="appearance-none"
                  placeholder="Expected graduation year"
                  value={formData.graduation_year}
                  onChange={(value) => handleChange("graduation_year", value)}
                  onInput={(e) => validateYear(e.target.value)}
                />
              </div>
            </div>
            {yearError && <p className="text-red-900 mb-4">{yearError}</p>}

            {errors.length > 0 && (
              <div className="text-red-900 text-xs mb-4">
                <ul>
                  {errors.map((error, index) => (
                    <li key={index} className="mb-1 block">
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="flex gap-2 items-center text-xs mb-2">
              <Input
                type="checkbox"
                // id="graduation"
                inputClass="5"
                value={checkboxOneChecked}
                onChange={(value) => handleCheckboxOneChange(value)}
              />
              <p>
                I consent to you contacting me about wHTa's products and
                services
              </p>
            </div>
            <div className="flex gap-2 text-xs items-center mb-3">
              <Input
                type="checkbox"
                // id="graduation"
                inputClass="5"
                value={checkboxTwoChecked}
                onChange={(value) => handleCheckboxTwoChange(value)}
              />
              <p>
                I have read the{" "}
                <a href="" className="text-[#E917BB]">
                  Privacy Policy
                </a>
                , and I agree to the{" "}
                <a href="" className="text-[#E917BB]">
                  Terms of service
                </a>
              </p>
            </div>
            <div className="mt-6 w-full flex justify-center">
              <SingleClick
                buttonClass="10"
                buttonText="Agree and Continue"
                buttonType="submit"
                // onClick={saveData}
                disabled={!checkboxOneChecked || !checkboxTwoChecked}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
