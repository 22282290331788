import { React, useState } from "react";
import PropTypes from "prop-types";

function SingleClick({
  buttonType = "button",
  buttonClass,
  buttonText,
  buttonImage = null,
  onClick,
  fileUrl,
  disabled,
}) {
  const handleClick = () => {
    if (fileUrl) {
      // Create a full URL using the public URL
      const fullUrl = `${window.location.origin}${fileUrl}`;
      console.log("Attempting to download:", fullUrl);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = fullUrl;
      link.target = "_blank";
      link.download = fileUrl.split("/").pop() || "download.pdf";

      // Append to the document body and trigger the click
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    }
    if (onClick) {
      onClick();
    }
  };
  if (buttonClass === "1") {
    buttonClass =
      "border-2 border-purple-darker p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8 rounded-lg text-md lg:text-xl text-purple-darker";
  } else if (buttonClass === "2") {
    buttonClass =
      "border-none bg-purple-darker p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8 rounded-xl text-md lg:text-xl text-white-a";
  }
  // button without word wrap
  else if (buttonClass === "3") {
    buttonClass =
      "border-2 border-purple-darker p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8 rounded-lg text-md lg:text-xl text-purple-darker bg-white";
  } else if (buttonClass === "4") {
    buttonClass =
      "flex flex-row items-center border-none bg-purple-darker p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-8 rounded-xl text-md lg:text-lg text-white-a justify-between font-dm-sans mx-2 overflow-hidden whitespace-nowrap hidden md:block";
  } else if (buttonClass == "5") {
    buttonClass =
      "flex items-center border-none bg-purple-darker p-1 sm:p-1 py-2 rounded-md lg:p-2 px-4 sm:px-3 lg:px-8 rounded-l text-sm lg:text-lg text-white-a ";
  } else if (buttonClass === "6") {
    buttonClass =
      "border-2 border-purple-dark-hover p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8 rounded-md  text-md lg:text-xl text-purple-dark-hover whitespace-nowrap";
  } else if (buttonClass === "7") {
    buttonClass =
      "flex flex-row border-none bg-purple-darker p-2 sm:p-2.5 lg:p-3 px-10 sm:px-12 lg:px-14 rounded-xl text-md lg:text-xl text-white-a justify-between";
  } else if (buttonClass === "8") {
    buttonClass =
      "border-none bg-purple-darker  py-1 px-6 sm:px-10 lg:px-16 rounded-xl text-md lg:text-xl text-white-a font-dm-sans ml-3 hover:bg-[#f1f1f1] hover:text-[#fd00e4] duration-[0.2s]";
  } else if (buttonClass === "9") {
    buttonClass =
      "border-none bg-purple-darker font-poppins p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8  text-md lg:text-xl text-white-a rounded-xl duration-[0.3s]";
  } else if (buttonClass === "10") {
    buttonClass =
      "flex flex-row items-center border-none bg-purple-darker rounded-xl p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-md lg:text-xl text-white-a justify-between font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker ";
  } else if (buttonClass === "11") {
    buttonClass =
      "border-none p-2 sm:p-2.5   sm:px-6   text-md lg:text-2xl w-fit bg-white-a font-semibold text-purple-darker rounded-xl";
  } else if (buttonClass === "12") {
    buttonClass =
      "border-none p-2 sm:p-2  px-2 sm:px-4 lg:px-8 my-2 text-xs lg:text-sm w-fit bg-white-a font-semibold text-purple-darker rounded-xl";
  } else if (buttonClass === "13") {
    buttonClass =
      "h-11 p-2 sm:p-2  px-2 sm:px-4 lg:px-8 my-2 text-xs lg:text-sm w-fit bg-purple-darker  text-white-a rounded-br rounded-tr";
  } else if (buttonClass === "14") {
    buttonClass =
      "h-11 p-2 sm:p-2  px-2 sm:px-4 lg:px-8 my-2 text-xl w-fit bg-gray-400  text-white-a rounded-br rounded-tr rounded-tl rounded-bl";
  } else if (buttonClass === "15") {
    buttonClass =
      "flex flex-row items-center border-none bg-purple-darker rounded-xl p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-md lg:text-xl text-white-a justify-center font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker ";
  } else if (buttonClass === "16") {
    buttonClass =
      "flex flex-row items-center border-none bg-custom-purple rounded-xl p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-md lg:text-xl text-white-a justify-center font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker ";
  } else if (buttonClass === "17") {
    buttonClass =
      "flex flex-row items-center border-none bg-purple-darker rounded-xl p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-md lg:text-xl text-white-a justify-center font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker ";
  } else if (buttonClass === "18") {
    buttonClass =
      "flex flex-row  w-[5.5rem] items-center border-none bg-purple-darker rounded-xl p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-sm lg:text-sm text-white-a justify-center font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker  ";
  } else if (buttonClass === "19") {
    buttonClass =
      "flex flex-row items-center border-none bg-custom-purple  p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6  lg:px-14 text-md lg:text-xl text-white-a justify-center font-dm-sans mx-2 overflow-hidden whitespace-nowrap hover:bg-gray-200 hover:text-purple-darker w-full";
  } else if (buttonClass === "20") {
    buttonClass =
      "border-2 border-purple-darker p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-6 rounded-lg text-md lg:text-[1rem] text-purple-darker bg-white hover:bg-purple-darker hover:text-white-a";
  } else if (buttonClass === "21") {
    buttonClass =
      "border-2 text-black  p-1 sm:p-1.5 lg:p-2 px-4 sm:px-6 lg:px-8  text-sm lg:text-  hover:bg-purple-darker hover:text-white bg-white rounded-xl";
  }
  //if button is disabled
  if (disabled) {
    buttonClass += " opacity-50 cursor-not-allowed";
  }

  return (
    <button
      type={buttonType}
      className={buttonClass}
      onClick={handleClick}
      disabled={disabled}
      style={{ transform: "scale(1)" }}
      onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1 )"}
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}
    >
      {buttonImage && (
        <img
          src={buttonImage}
          alt="Button Icon"
          className="w-7 h-7 rounded-full  mr-4" // Adjust the size and margin as needed
        />
      )}
      {buttonText}
    </button>
  );
}

SingleClick.propTypes = {
  buttonType: PropTypes.oneOf(["button", "submit", "reset"]),
  // add the number of button classes here...
  buttonClass: PropTypes.oneOf([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
  ]),

  buttonText: PropTypes.string,
  buttonImage: PropTypes.string, // Added prop type for button image
  onClick: PropTypes.func, //Added prop type for functions
  fileUrl: PropTypes.string, // Added prop type for file URL
  disabled: PropTypes.bool,
};

export default SingleClick;
