import React from "react";
import { ServicesHero, Whycus } from "../../../components/index";
import { BannerSection } from "../../../components/micros/index";
import { SoftwareService } from "../../../components/major";
import { SoftwareSolution } from "../../../components/major";

function SoftwareDev() {
  return (
    <>
      <ServicesHero
        serviceTitle="Software Development"

        serviceTitleImg="/icons/webdevTittle.png"

        serviceDescription1="From basic desktop applications to complex enterprise solutions, mobile apps, and web-based platforms, we have the expertise and experience to handle projects of any size and complexity."
        serviceDescription2="Our collaborative approach ensures that we understand your requirements thoroughly, allowing us to deliver solutions that exceed your expectations."
        serviceImg="/serviceHero2Objects.svg"
      />

      {/* first banner */}
      <div className="flex items-center justify-center ">
        <BannerSection
          backgroundImage="/icons/softwareBanner1.png"
          desc="“Explore our range of software development packages below and take the first step towards transforming your vision into reality.”"
        />
      </div>

      {/* <SoftwareService /> */}
      <SoftwareSolution />
      <Whycus />
      {/* 2nd banner */}
      <div className="flex items-center justify-center ">
        <BannerSection
          backgroundImage="/icons/softwareBanner2.png"
          desc="Tired of software that feels like it showed up in a generic cardboard box?  Dream up your perfect solution. We'll build it!"
          btnText="Kickstart Your Project"
        />
      </div>
    </>
  );
}

export default SoftwareDev;
