import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Cloud platforms such as AWS",
    "Cloud security services and tools",
    "Cloud-native security control",
    "Kubernetes",
    "Docker",
    "Infrastructure as code (IaC) tools",
    "Terraform",
    "GDPR",
    "SOC 2",
    "CIS Benchmarks",
    "cloud security monitoring",
    "Security audits",
    "Risk assessments",
    "Network security concepts and protocols",
    "Encryption technologies",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "mentorship Skills",
    "Leadership skills",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Develop and implement cloud security strategies and policies aligned with industry standards.",
    "Review cloud architectures for security compliance, offering improvement suggestions.",
    "Manage IAM policies to control access and ensure proper authentication.",
    "Implement encryption and key management for data protection in the cloud.",
    "Configure and maintain network security controls such as VPNs, firewalls, and segmentation to secure cloud networks and prevent unauthorized access.",
    "Ensure compliance with security standards through audits and assessments.",
    "Conduct threat modeling exercises and risk assessments to identify and prioritize security risks, developing mitigation strategies to address them.",
    "Maintain incident response plans for handling security breaches.",
    "Utilize automation tools to enhance security operations and incident response.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
    "Previous experience in cloud security or related roles with a proven track record.",
    "Certifications such as CCSP, AWS Certified Security Specialty, or Azure Security Engineer are advantageous.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Cloud Security Architect"}
          Description={
            "We are currently seeking a highly skilled and experienced Cloud Security Architect to join our team. As a Cloud Security Architect, you will be responsible for designing and implementing secure cloud architectures, ensuring the confidentiality, integrity, and availability of our cloud-based systems and data. The ideal candidate will possess a strong technical background in cloud platforms, coupled with expertise in cloud security controls and best practices."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
