// StepperContext.jsx
import React, { createContext, useContext, useState } from "react";

const StepperContext = createContext();

export const useStepper = () => useContext(StepperContext);

export const StepperProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  
  // State to store form data
  const [formData, setFormData] = useState({});

  const handleNext = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep + 1;
      setIsLastStep(nextStep === 2); // Assuming 2 is the last step index
      setIsFirstStep(nextStep === 0);
      return nextStep;
    });
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => {
      const prevStepUpdated = prevStep - 1;
      setIsLastStep(prevStepUpdated === 2); // Assuming 2 is the last step index
      setIsFirstStep(prevStepUpdated === 0);
      return prevStepUpdated;
    });
  };

  // Function to update form data
  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        isLastStep,
        isFirstStep,
        handleNext,
        handlePrev,
        setActiveStep,
        formData,
        updateFormData, // Provide the update function
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
