import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Terraform",
    "Ansible",
    "Docker",
    "Kubernates",
    "Jenkins",
    "Git Lab CI",
    "Circle CI",
    "AWS",
    "Azure",
    "Prometheus",
    "Splunk",
    "Python",
    "Base",
    "Powershell",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Organization",
    "Attention to detail",
    "Adaptability",
    "Creativity",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Implementing infrastructure automation using tools such as Terraform or Ansible, enabling consistent and efficient provisioning of resources.",
    "Managing containerization and orchestration platforms like Docker and Kubernetes, optimizing resource utilization and scalability.",
    "Designing and maintaining continuous integration and deployment (CI/CD) pipelines using tools like Jenkins, GitLab CI, or CircleCI, automating the software delivery process.",
    "Managing cloud platforms such as AWS, Azure, or Google Cloud Platform, leveraging cloud-native services to enhance system capabilities.",
    "Implementing monitoring and logging solutions using tools like Prometheus, ELK stack, or Splunk, ensuring timely detection and resolution of issues.",
    "Utilizing scripting languages such as Bash, Python, or PowerShell to automate routine tasks and streamline operations.",
    "Implementing networking concepts and security best practices for cloud environments, ensuring data protection and compliance.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Engineering, or related field, or equivalent experience.",
    "Previous experience in DevOps or related roles, with a proven track record of successful projects and implementations.",
    "Certifications such as AWS Certified DevOps Engineer, Certified Kubernetes Administrator (CKA), or Certified Jenkins Engineer are advantageous.",
    "Strong problem-solving abilities and analytical thinking.",
    "Effective communication and collaboration skills.",
    "Attention to detail and commitment to quality.",
    "Adaptability to learn new technologies and methodologies.",
    "Time management and ability to prioritize tasks in a fast-paced environment.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"DevOps Engineer"}
          Description={
            "We are currently seeking a highly skilled and motivated DevOps Engineer to join our team. As a DevOps Engineer, you will play a critical role in implementing and managing our infrastructure and deployment processes, ensuring the reliability, scalability, and security of our systems."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
