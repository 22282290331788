// src/services/studentApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from './authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:8000/api',
    prepareHeaders: (headers) => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

// Enhanced base query to handle token expiration
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        // If the access token has expired, try to refresh the token
        const refreshResult = await baseQuery('/refresh-token', {
            method: 'POST',
            body: { refresh: sessionStorage.getItem('refreshToken') },
        });

        if (refreshResult?.data) {
            // Store the new access token
            sessionStorage.setItem('accessToken', refreshResult.data.access);
            api.dispatch(authSlice.actions.setAccessToken(refreshResult.data.access));

            // Retry the original request with the new token
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return result;
};



export const adminApi= createApi({
    reducerPath: 'adminApi ',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => '/profile/',
        }),
        // Add other endpoints here
    
    
        getDashboard: builder.query({
            query: () => '/elearning/analytics-dashboard/',
        }),
       


    }),
    
});

export const { useGetProfileQuery ,useGetDashboardQuery} = adminApi;
