// src/micros/utilities/dataUtilHealthcare.js

export const HealthcareAdvantages = [
    { 
        image: "Clip path group.svg",
        title: "IT Expert",
        description: "We're ready to turn your ideas into cutting-edge solutions with our deep IT Expertise.",
    },
    {
        image: "Group.svg",
        title: "Transparency",
        description: "We openly share our processes, providing insight into client collaboration",
    },
    {
        image: "Group (1).svg",
        title: "24/7 IT Support",
        description: "Our team ensures constant system operation and proactive monitoring 24/7.",
    },
    {
        image: "Clip path group (1).svg",
        title: "Pricing",
        description: "Exceptional Quality, Friendly Prices - Your Ideal Software Development Partner",
    }, 
];

    
    