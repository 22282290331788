import React from 'react'
import { WhyChooseUs } from "../../../micros/index";

function WhyProdDesign() {
    const whyusData = [
        {
            title: "Innovative Approach",
            description:"We embrace creativity and innovation to develop unique and impactful product designs that stand out in the market.",
            icon: "icons/brain.svg",
          },  
        {
            title: "User-Centered Design",
            description:"Our design process prioritizes understanding your target audience, ensuring your products resonate and provide a seamless experience.",
            icon: "icons/softdev.svg",
          },
        {
            title: "Collaborative Partnership", 
            description: "We value collaboration and transparency, partnering closely with our clients to bring their vision to reality during the design process",
            icon: "icons/headset1.svg",
          },
        {
            title: "Cross-Disciplinary Expertise",
            description:"Our design process ensures products resonate with users for a seamless experience.",
            icon: "icons/money.svg",
        },
        {
            title: "Iterative Approach",
            description:"We follow an iterative design process, incorporating feedback and refining designs continuously to achieve the best possible outcome.",
            icon: "icons/money.svg",
        },
      ];
    
      return (
        <div className="w-full bg-purple-f">
          <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
            Why choose us
          </h2>
          <div className='flex items-center justify-center'>

            <div className="flex flex-wrap items-center justify-center lg:justify-between gap-4 pb-[4rem] sm:pb-[6rem] lg:pb-[12rem]">
                {whyusData.map((whyus, index) => (
                <WhyChooseUs {...whyus} key={index} />
                ))}
            </div>
          </div>
        </div>
      );
}

export default WhyProdDesign