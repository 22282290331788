import React, { useState, useRef } from "react";

import SingleClick from "../../micros/actions/SingleClick";
import LanguageSwitcher from "../../micros/translator/Translator";
import { useLocation } from "react-router-dom";

const Navbar1 = () => {
  const [showLinks, setShowLinks] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownVisible2, setDropdownVisible2] = useState(false);
  const location = useLocation(); // Get current location
  const timeoutRef = useRef(null);

  const navLinks = [
    { to: null, label: "Services", withArrow: true },
    // {
    //   to: "https://academy.whtanetworks.com/",
    //   label: "E-Learning",
    //   withArrow: false,
    // },

    { to: "/careers", label: "Careers", withArrow: false },
    { to: "/cybersecurity", label: "Cybersecurity", withArrow: false },
    // { to: "/wHTa-campus", label: "Campus Hub", withArrow: false },
    { to: "https://elitetechdefender.com/", label: "Blog", withArrow: false },
  ];

  const handleNavLinkClick = (label) => {
    if (label === "Services") {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const handleMouseEnter = (label) => {
    if (label === "Services") {
      timeoutRef.current = setTimeout(() => {
        setDropdownVisible(true);
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDropdownVisible(false);
  };
  // const handleMouseLeave2 = () => {
  //   setDropdownVisible(false);
  // };

  const isActiveLink = (to) => {
    return location.pathname === to;
  };

  const isDropdownActive = () => {
    return (
      location.pathname.startsWith("/software-dev") ||
      location.pathname.startsWith("/product-design") ||
      location.pathname.startsWith("/itconsulting")
    );
  };

  return (
    <nav
      className="bg-white p-[0.15rem] fixed top-0 w-full z-20 justify-evenly flex"
      // onMouseLeave={handleMouseLeave}
    >
      <div className="container relative mx-auto 2xl:w-full xl:w-full 2xl:mx-auto xl:mx-auto flex justify-between items-center w-full lg:w-auto">
        <NavLink to="/" disableHover>
          <div className="flex items-center gap-2 justify-center mr-2">
            <img src="/icons/whta.svg" alt="Whta Icon" className="w-18 h-18" />
            <span className="font-bold text-[2rem] lg:text-[2.8rem] bg-gradient-to-b from-[#00ffa3] to-[#fd00e4] text-transparent bg-clip-text">
              wHTa
            </span>
          </div>
        </NavLink>

        {/* Navigation Links (visible on smaller screens) */}
        {showLinks && (
          <div className="md:hidden absolute w-full top-20 right-0 bg-blue-50 text-center rounded-md mb-2 font-dm-sans py-4">
            <ul className="list-none p-0 flex flex-col justify-between mb-2">
              {navLinks.map(({ to, label, withArrow }) => (
                <div
                  key={to || label}
                  className="relative flex flex-col items-center"
                >
                  <div className="flex flex-row">
                    {withArrow ? (
                      <button
                        onMouseEnter={() => handleNavLinkClick(label)}
                        className={`flex flex-row items-center hover:bg-purple-darker hover:text-white p-4 rounded-3xl ${
                          isDropdownActive()
                            ? "bg-purple-darker text-white"
                            : "hover:bg-purple-darker hover:text-white"
                        }`}
                      >
                        {label}
                        {/* <img
                            src="/arrowdown.svg"
                            alt="Arrow Icon"
                            className="pl-1"
                          /> */}
                      </button>
                    ) : (
                      <NavLink1 to={to} isActive={isActiveLink(to)}>
                        {label}
                      </NavLink1>
                    )}
                  </div>

                  {isDropdownVisible && label === "Services" && (
                    <div className="absolute top-full left-0 w-full bg-white p-4 pb-20 border rounded shadow text-black max-h-[80vh] overflow-y-auto mt-2 z-20">
                      {/* Dropdown Content */}
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {/* Software Development Column */}
                        <div className="flex flex-col mb-4">
                          <span className="font-bold text-black text-[15px]">
                            Software Development
                          </span>
                          <div className="pl-0 text-[14px]">
                            <NavLink to="/software-dev">
                              SaaS Development
                            </NavLink>
                            <NavLink to="/software-dev">
                              Web-based applications
                            </NavLink>
                            <NavLink to="/software-dev">
                              Website development
                            </NavLink>
                            <NavLink to="/software-dev">Mobile apps</NavLink>
                            <NavLink to="/software-dev">ERP Systems</NavLink>
                            <NavLink to="/software-dev">
                              Chatbots Integration
                            </NavLink>
                            <NavLink to="/software-dev">
                              Chatbots development
                            </NavLink>
                          </div>
                        </div>

                        {/* Product Design Column */}
                        <div className="flex flex-col mb-4">
                          <span className="font-bold text-black text-[15px]">
                            Product Design
                          </span>
                          <div className="pl-0 text-[14px]">
                            <NavLink to="/product-design">
                              Product idealization
                            </NavLink>
                            <NavLink to="/product-design">
                              User research
                            </NavLink>
                            <NavLink to="/product-design">
                              Wire-framing & Prototyping
                            </NavLink>
                            <NavLink to="/product-design">UI/UX Design</NavLink>
                            <NavLink to="/product-design">
                              Design validation testing
                            </NavLink>
                          </div>
                        </div>

                        {/* IT Consulting Column */}
                        <div className="flex flex-col">
                          <span className="font-bold text-black text-[17px]">
                            IT Consulting
                          </span>
                          <div className="pl-0 text-[16px]">
                            <NavLink to="/itconsulting?IT-Infrastructure-Management">
                              <p>IT Infrastructure Management</p>
                            </NavLink>
                            <NavLink to="/itconsulting?Cybersecurity-and-Risk-Management">
                              <p>Cybersecurity and Risk Management</p>
                            </NavLink>
                            <NavLink to="/itconsulting?Incident-Response-and-Planning">
                              <p>Incident Response and Planning</p>
                            </NavLink>
                            <NavLink to="/itconsulting?Social-Media-Investigations">
                              <p>Social Media Investigations</p>
                            </NavLink>
                          </div>
                        </div>

                        {/* Service Areas Column 
                        <div className="flex flex-col mb-4">
                          <span className="font-bold text-black text-[15px]">
                            Service Areas
                          </span>
                          <div className="pl-0 text-[14px]">
                            <NavLink to="/">Banking & Finance</NavLink>
                            <NavLink to="/">Healthcare</NavLink>
                            <NavLink to="/">Retail</NavLink>
                            <NavLink to="/">Lifestyle and Travel</NavLink>
                            <NavLink to="/">E-Commerce</NavLink>
                            <NavLink to="/">Logistics</NavLink>
                            <NavLink to="/">Ed Tech</NavLink>
                            <NavLink to="/">Hotel & Accommodation</NavLink>
                            <NavLink to="/">IT Sourcing & Consulting</NavLink>
                            <NavLink to="/">IT Asset Management</NavLink>
                            <NavLink to="/">NGOs</NavLink>
                            <NavLink to="/">Startups</NavLink>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="mt-4">
                <NavLink to="/contact-us">
                  <SingleClick
                    buttonClass="9"
                    buttonType="button"
                    buttonText="Get Started"
                  />
                </NavLink>
              </div>
            </ul>
          </div>
        )}

        {/* Navigation Links (visible on larger screens) */}

        <div className="hidden md:flex container items-center justify-around overflow-x-auto lg:m-auto md:m-auto sm:text-sm md:text-[0.83rem] lg:text-[1.3rem] font-dm-sans">
          {navLinks.map(({ to, label, withArrow }) => (
            <div key={to || label} className="flex flex-col items-center">
              <div className="flex flex-row">
                {withArrow ? (
                  <button
                    onMouseEnter={() => handleMouseEnter(label)}
                    // onMouseLeave={() => handleMouseLeave()}
                    className={`flex flex-row items-center hover:bg-purple-darker hover:text-white p-4 rounded-3xl  ${
                      isDropdownActive()
                        ? "bg-purple-darker text-white"
                        : "hover:bg-purple-darker hover:text-white"
                    }`}
                  >
                    {label}
                    {/* <img
                      src="/arrowdown.svg"
                      alt="Arrow Icon"
                      className="pl-1"
                    />*/}
                  </button>
                ) : (
                  <NavLink1 to={to} isActive={isActiveLink(to)}>
                    {label}
                  </NavLink1>
                )}
              </div>
              {isDropdownVisible && label === "Services" && (
                <div
                  onMouseLeave={handleMouseLeave}
                  className="absolute overflow-y-auto top-20 left-20 bg-white p-4 border rounded shadow text-black grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
                >
                  {/* Software Development Column */}
                  <div className="flex flex-col">
                    <span className="font-bold text-black text-[17px]">
                      Software Development
                    </span>
                    <div className="pl-0 text-[16px] hover:p-0">
                      <NavLink to="/software-dev">
                        <p>SaaS Development</p>
                      </NavLink>
                      <NavLink to="/software-dev">
                        <p>Web-based applications</p>
                      </NavLink>
                      <NavLink to="/software-dev">
                        <p>Website development</p>
                      </NavLink>
                      <NavLink to="/software-dev ">
                        <p>Mobile apps</p>
                      </NavLink>
                      <NavLink to="/software-dev">
                        <p>ERP Systems</p>
                      </NavLink>
                      <NavLink to="/software-dev">
                        <p>Chatbots Integration</p>
                      </NavLink>
                      <NavLink to="/software-dev">
                        <p>Chatbots development</p>
                      </NavLink>
                    </div>
                  </div>

                  {/* Product Design Column */}
                  <div className="flex flex-col">
                    <span className="font-bold text-black text-[17px]">
                      Product Design
                    </span>
                    <div className="pl-0 text-[16px]">
                      <NavLink to="/product-design">
                        <p>Product idealization</p>
                      </NavLink>
                      <NavLink to="/product-design">
                        <p>User research</p>
                      </NavLink>
                      <NavLink to="/product-design">
                        <p>Wire-framing & Prototyping</p>
                      </NavLink>
                      <NavLink to="/product-design">
                        <p>UI/UX Design</p>
                      </NavLink>
                      <NavLink to="/product-design">
                        <p>Design validation testing</p>
                      </NavLink>
                    </div>
                  </div>

                  {/* IT Consulting Column */}
                  <div className="flex flex-col">
                    <span className="font-bold text-black text-[17px]">
                      IT Consulting
                    </span>
                    <div className="pl-0 text-[16px]">
                      <NavLink to="/itconsulting?IT-Infrastructure-Management">
                        <p>IT Infrastructure Management</p>
                      </NavLink>
                      <NavLink to="/itconsulting?Cybersecurity-and-Risk-Management">
                        <p>Cybersecurity and Risk Management</p>
                      </NavLink>
                      <NavLink to="/itconsulting?Incident-Response-and-Planning">
                        <p>Incident Response and Planning</p>
                      </NavLink>
                      <NavLink to="/itconsulting?Social-Media-Investigations">
                        <p>Social Media Investigations</p>
                      </NavLink>
                    </div>
                  </div>

                  {/* Service Areas Column 
                  <div className="flex flex-col">
                    <span className="font-bold text-black text-[17px]">
                      Service Areas
                    </span>
                    <div className="pl-0 text-[16px]">
                      <NavLink to="/">
                        <p>Banking & Finance</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Healthcare</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Retail</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Lifestyle and Travel</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>E-Commerce</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Logistics</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Ed Tech</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Hotel & Accommodation</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>IT Sourcing & Consulting</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>IT Asset Management</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>NGOs</p>
                      </NavLink>
                      <NavLink to="/">
                        <p>Startups</p>
                      </NavLink>
                    </div>
                  </div>*/}
                </div>
              )}
              {/*

              {isDropdownVisible2 && label === "Industries" && (
                <div
                  onMouseLeave={handleMouseLeave}
                  className="absolute overflow-y-auto top-20 right-50 bg-white p-4 border rounded shadow text-purple-darker"
                >
                  <NavLink to="/healthcare">HealthCare</NavLink>{" "}
                  <hr className="border-purple-darker" />
                  <NavLink to="/finance">Finance</NavLink>
                  <hr className="border-purple-darker" />
                  <NavLink to="/retail">Retail</NavLink>
                  <hr className="border-purple-darker" />
                  <NavLink to="/technology">Technology</NavLink>
                </div>

              )}*/}
            </div>
          ))}
          <NavLink to="/contact-us">
            <SingleClick
              buttonClass="9"
              buttonType="button"
              buttonText="Get started"
            />
          </NavLink>
          {/* <LanguageSwitcher /> */}
        </div>

        {/* Hamburger Menu */}
        <div className="md:hidden flex items-center mr-4 text-2xl">
          <button
            className="text-purple-dark"
            onClick={() => setShowLinks(!showLinks)}
          >
            &#9776; {/* Hamburger icon */}
          </button>
        </div>
      </div>
    </nav>
  );
};

// NavLink.js (Reusable component for navigation links)
const NavLink = ({ to, children, isActive, disableHover }) => (
  <a
    href={to}
    className={`block text-black ${
      isActive ? "bg-purple-darker text-white" : ""
    } ${
      disableHover
        ? ""
        : "hover:bg-gray-100 hover:text-purple-darker hover:drop-shadow hover:border-2"
    } block rounded-3xl items-center transition duration-300`}
  >
    {children}
  </a>
);

const NavLink1 = ({ to, children, isActive, disableHover }) => (
  <a
    href={to}
    target={to.startsWith("http") ? "_blank" : "_self"} //open on new tab
    //target="_self"
    rel={to.startsWith("http") ? "noopener noreferrer" : ""}
    className={`block text-black ${
      isActive ? "bg-purple-darker text-white" : ""
    } ${
      disableHover
        ? ""
        : "flex flex-row items-center hover:bg-purple-darker hover:text-white p-4 rounded-3xl"
    } block rounded-3xl items-center transition duration-300`}
  >
    {children}
  </a>
);

export default Navbar1;
