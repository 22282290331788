import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Machine learning algorithms",
    "Supervised Learning",
    "Unsupervised Learning",
    "Deep Learning",
    "scikit-learn",
    "PyTorch",
    "TensorFlow,",
    "neural network architectures (NLP) ",
    "model evaluation",
    "feature engineering",
    "data preprocessing",
    "Computer vision (CV) techniques",
    "Apache Spark",
    "Apache SparkKnowledge of cloud platforms",
    "software engineering principles",
    "Python, R, or Scala.",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "Ability to meet deadlines",
    "Analytical Skills",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Utilize proficiency in machine learning algorithms and techniques, including supervised learning, unsupervised learning, and deep learning, to develop innovative solutions.",
    "Implement machine learning models using frameworks and libraries such as TensorFlow, PyTorch, or scikit-learn, ensuring scalability and performance.",
    "Apply knowledge of data preprocessing, feature engineering, and model evaluation techniques to prepare and analyze datasets for model training.",
    "Design and optimize neural network architectures and optimization algorithms to improve model accuracy and efficiency.",
    "Leverage natural language processing (NLP) and computer vision (CV) techniques.",
    "Develop end-to-end machine learning pipelines, from data ingestion to model deployment, ensuring seamless integration with existing systems.",
    "Utilize distributed computing frameworks like Apache Spark t.",
    "Deploy and scale machine learning models on cloud platforms and services, ensuring reliability and scalability.",
    "Collaborate with data scientists and software engineers to integrate machine learning models into production systems and applications.",
    "Monitor and maintain machine learning systems, performing regular updates and optimizations to ensure optimal performance.",
    "Stay abreast of emerging trends and technologies in machine learning and artificial intelligence, continuously learning and improving skills.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Engineering, Mathematics, or related field (or equivalent experience).",
    "Previous experience in machine learning engineering or related roles with a proven track record.",
    "Advanced degree (Master's or Ph.D.) in a relevant field is advantageous.",
    "Certifications such as Google Cloud Professional Machine Learning Engineer or AWS Certified Machine Learning - Specialty are beneficial.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Machine Learning Engineer"}
          Description={
            "We are seeking a skilled and innovative Machine Learning Engineer to join our dynamic team. As a Machine Learning Engineer, you will be responsible for developing and deploying advanced machine learning models and solutions. This role offers an exciting opportunity to work on challenging projects, collaborate with cross-functional teams, and contribute to groundbreaking AI initiatives."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
