import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Python ",
    "Node.js",
    "Django",
    "Spring Boot",
    "Express.js",
    "SQL",
    "NOSQL",
    "RESTful API",
    "Optimization",
    "Git Hub",
    "Scalability",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Organization",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "Commitment to code quality",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Developing server-side applications (Server-Side Development).",
    "Managing database systems effectively (Database Management).",
    "Designing and implementing RESTful APIs (API Development).",
    "Implementing authentication and authorization mechanisms (Authentication and Authorization).",
    "Developing middleware components for seamless communication (Middleware Development).",
    "Ensuring backend performance optimization (Performance Optimization).",
    "Implementing security measures and compliance standards (Security and Compliance).",
    "Implementing error handling and logging mechanisms (Error Handling and Logging).",
    "Conducting testing and quality assurance of backend functionalities (Testing and Quality Assurance).",
    "Documenting processes and collaborating effectively within the team (Documentation and Collaboration).",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science or related field (or equivalent experience).",
    "Previous experience as a backend developer with a portfolio of projects.",
    "Certifications or additional training in backend development are advantageous.",
    "Knowledge of RESTful API design and development.",
    "Familiarity with cloud services and serverless architectures.",
    "Ability to optimize backend performance and scalability.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Backend Developer"}
          Description={
            "We are in search of a talented Backend Developer to join our team. The ideal candidate will have proficiency in backend programming languages such as Python or Node.js, along with experience in backend frameworks including Django, Spring Boot, or Express.js."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
