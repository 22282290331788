import React from 'react'

import AcademyPage from '../../components/sections/academyPage/AcademyPage'
import BrowseSection from '../../components/sections/academyPage/browseAcademy/BrowseAcademy'
const Academy = () => {
  return (
    <div>
      
     <AcademyPage/>
     
      </div>

  )
}

export default Academy