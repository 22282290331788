import React, { useState, useEffect } from "react";
import {
  HalfHeroSection,
  ApplicationSection,
  ApplicationBanner,
} from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);

  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Android (Java/Kotlin)",
    "iOS (Swift/Objective-C)",
    "mobile app frameworks",
    "Flutter",
    "React Native",
    "Xamarin",
    "UI/UX design principles",
    "Backend development",
    "mobile app architecture patterns (MVC, MVVM)",
    "optimization",
    "GIT",
    "testing and debugging",
    "mobile security best practices",
    "Java, Kotlin, Swift, or Objective-C",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "Ability to meet deadlines",
    "Analytical Skills",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Prepare and preprocess datasets, including data cleaning, transformation, and feature extraction tasks.",
    "Develop machine learning models using state-of-the-art algorithms and techniques, training them on diverse datasets to achieve desired performance objectives.",
    "Select appropriate machine learning algorithms based on project requirements, optimizing model parameters and hyperparameters to improve performance.",
    "Perform feature engineering to extract relevant information from raw data, identifying and creating informative features to enhance model accuracy.",
    "Evaluate and validate model performance using appropriate metrics and techniques, including validation on unseen data to assess generalization capability.",
    "Tune model hyperparameters for optimization and generalization, deploying models into production environments and integrating them with existing systems and applications.",
    "Monitor and maintain deployed machine learning systems for performance, stability, and reliability, collaborating with data scientists and software engineers to integrate solutions into larger projects.",
    "Work closely with cross-functional teams to drive project success, staying updated on the latest machine learning techniques and technologies through continuous self-learning and professional development opportunities.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Software Engineering, or related field (or equivalent experience).",
    "Previous experience in mobile app development or related roles with a portfolio of projects.",
    "Certifications in mobile app development platforms (Google Certified Associate Android Developer, Apple Developer Certification) are beneficial.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Mobile Apps Developer"}
          Description={
            "We are seeking a skilled and innovative Mobile Apps Developer to join our dynamic team. The ideal candidate will be responsible for the design, development, and implementation of our mobile applications, ensuring their functionality, usability, and alignment with our company's goals and objectives."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
