import React from 'react';
import { SingleClick } from '../../micros/index';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({ title, index, description, cardClass, btnText=null, tittleClass }) => {
  if(cardClass === "1") {
    cardClass = 'border-t-[.4rem] border-t-purple-darker h-[28rem] drop-shadow-xl max-w-[16rem] w-full rounded-br-[3rem] text-purple-darker hover:text-white-a hover:bg-purple-darker bg-white-a transition-all duration-500';
  } else if(cardClass === "2") {
    cardClass = ' flex border-t-[.4rem] border-t-purple-darker h-[28rem] md:h-[35rem] drop-shadow-xl max-w-[17rem] w-full rounded-br-[3rem] text-purple-darker hover:text-white-a hover:bg-purple-darker bg-white-a transition-all duration-500 relative';
  }

  if(tittleClass === "1") {
    tittleClass = 'font-poppins-bold md:text-[1.7rem] text-xl mt-12 mb-4'; // Changed mb-10 to mb-4
  } else if(tittleClass === "2") {
    tittleClass = 'font-poppins-bold text-xl lg:text-3xl mt-12 mb-4 whitespace-none'; // Changed mb-10 to mb-4
  }

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/academy');
  };

  return (    
    <>
      <section className={cardClass} key={index} >
        <div className='p-1'>
          <h1 className={`${tittleClass} font-poppins-semi-bold text-balance text-2xl md:py-0`}>{title}</h1>
          <br /> {/* Added a line break */}
          <p className=' text-lg  text-wrap text-clip text-center md:py-5 font-poppins'>{description}</p>
        </div>
        <div className='hover:bg-transparent mt-[4rem] w-full  absolute bottom-8 px-32 flex items-center justify-center'>
          {btnText ? (
            <SingleClick
              buttonType="button"
              buttonClass='3'
              buttonText={btnText}
              onClick={handleClick}
              
            />
          ) : ('')}
        </div>
      </section>
    </>
  );
};

ServiceCard.propTypes = {
  cardClass: PropTypes.oneOf(['1', '2']),
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  tittleClass: PropTypes.oneOf(['1', '2']),
};

export default ServiceCard;
