import React from 'react';

import ServicesHerosection from '../../../major/servicesPage/hero/ServicesHero';
const ServicesHero = ({
  serviceTitleImg,
  serviceTitle,
  serviceDescription1,
  serviceDescription2,
  serviceImg,
}) => {
  return (

  <div className=" flex justify-center ">
      <div className=" items-center justify-between sm:justify-around pt-24 w-full">
        <ServicesHerosection
         serviceDescription1={serviceDescription1}
         serviceDescription2={serviceDescription2}
         serviceTitle={serviceTitle}
         serviceTitleImg={serviceTitleImg}
         serviceImg={serviceImg}
         />

      </div>
    </div>)}
export default ServicesHero;
