const Solutions = () => {
  return (
    <div className="bg-[#E5E5E5]/30 p-4">
      
        <h1 className="text-lg lg:text-xl font-bold text-gray-800  my-8 p-4 text-center">
          Comprehensive Cybersecurity Solutions
        </h1>
    

      <div className="flex flex-row gap-4 mx-10 w-3/3 mb-6 pb-10">
        <div className="border rounded-lg bg-white p-4 flex flex-col m-2 text-center w-1/3 pb-10 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#9C1583]/50">
          <span className="text-[#1C0A6D] text-base mb-6">Penetration Testing</span>
          <p>We Identify and fix <br/> vulnerabilities <br/> before hackers do.</p>
        </div>
        <div className="border rounded-lg bg-white p-4 flex flex-col m-2 text-center w-1/3 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#9C1583]/50">
          <span className="text-[#1C0A6D] text-base mb-6">Cyber Threat Intelligence</span>
          <p>We Leverage actionable <br/> insights to  <br/> combat evolving threats.</p>
        </div>
        <div className="border rounded-lg bg-white p-4 flex flex-col m-2 text-center w-1/3  transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#9C1583]/50">
          <span className="text-[#1C0A6D] text-base mb-6">Real-time Monitoring</span>
          <p>Stay protected with 24/7 <br/> surveillance <br/> and immediate responses</p>
        </div>
      </div>
    </div>
  );
};
export default Solutions;
