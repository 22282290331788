/* eslint-disable no-unused-vars */
import React from "react";
import { Services } from "../../../major/index";

const Service = () => {
  return (
    <div className="bg-purple-f flex items-center justify-center ">
      <Services />
    </div>
  );
};

export default Service;