import React from "react";
import { Hero0, Hero1 } from "../../../major/index.jsx";

function Hero() {
  return (
    <div className="h-screen max-h-[59rem] w-full flex justify-center pt-16 px-8 sm:px-12 lg:px-24 xl:px-32">
      <div className="bg-element1 bg-cover bg-center h-full flex justify-center items-center bg-no-repeat w-full">
          <div className="flex flex-wrap sm:flex-nowrap max-w-8xl w-full h-full max-h-[xl] items-center justify-between sm:justify-around ">
        <Hero0 />
        <Hero1 />
      </div>
      </div>
    
    </div>
  );
}

export default Hero;
