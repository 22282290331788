import React from "react";
import "./App.css";
import PropTypes from "prop-types";

const ApplicationSection = ({
  Title,
  Description,
  technicalSkills,
  softSkills,
  responsibilities,
  qualifications,
}) => {
  return (
    <div>
      <div className="container-app">
        <div className="header-container">
          <h1 className="title">{Title}</h1>
          <p className="company">wHTa Company</p>
          <p className="tag">Remote</p>
          <p className="tag internship-tag">Internship</p>
        </div>
      </div>

      <div className="AppBody">
        <h1 className="description-title">Description :</h1>
        <p className="description">{Description}</p>
        <h1 className="description-title">Required Skills :</h1>
        <h1 className="skills-title">Technical Skills :</h1>
        <div className="skills-container">
          <p className="skill">
            {technicalSkills.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill}
              </span>
            ))}
          </p>
        </div>
        <h1 className="skills-title">Soft Skills :</h1>
        <div className="skills-container">
          <p className="skill">
            {softSkills.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill}
              </span>
            ))}
          </p>
        </div>
        <div>
          <h1 className="description-title">Roles and Responsibilities</h1>
          <ul className="bullet-list">
            {responsibilities.map((responsibility, index) => (
              <li key={index}>{responsibility}</li>
            ))}
          </ul>
        </div>
        <div className="Qualifications">
          <h1 className="description-title">Qualifications</h1>
          <ul className="bullet-list">
            {qualifications.map((qualification, index) => (
              <li key={index}>{qualification}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

ApplicationSection.propTypes = {
  Title: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  technicalSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
  softSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
  responsibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ApplicationSection;
