import React, { useState } from "react";
import axios from "axios";

import { Input, SingleClick, TextArea } from "../../../micros";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { credentials, config } from "../../../../auth";

function StepFour({ onNext, closePopup, formData, updateFormData }) {
  const [paymentNo, setPaymentNo] = useState(formData?.phone_number || "");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  // console.log(formData.phone_number);

  const handleChange = (value) => {
    setPaymentNo(value);
    if (value?.length >= 10) {
      setPhoneError("");
    }
  };

  const handlePay = async () => {
    if (!paymentNo || paymentNo?.length < 10) {
      setPhoneError("Phone number must be at least 10 digits.");
      return;
    }
    // console.log("form data sent", formData)
    setLoading(true);
    setPaymentError("");

    const formDataSend = new FormData();
    formDataSend.append("full_name", formData.full_name);
    formDataSend.append("student_email", formData.student_email);
    formDataSend.append("phone_number", formData.phone_number.slice(1));
    formDataSend.append("registration_number", formData.registration_number);
    formDataSend.append("course_enrolled", formData.course_enrolled);
    formDataSend.append("institution", formData.institution);
    formDataSend.append("faculty_school", formData.faculty_school);
    formDataSend.append("department", formData.department);
    formDataSend.append("year_enrolled", formData.year_enrolled);
    formDataSend.append("graduation_year", formData.graduation_year);
    formDataSend.append("student_id", formData.student_id);
    formDataSend.append("selfie_with_id", formData.selfie_with_id);
    formDataSend.append("pitch_description", formData.pitch_description);
    formDataSend.append("unique_elements", formData.unique_elements);
    formDataSend.append("target_audience", formData.target_audience);
    formDataSend.append("unique_elements", formData.unique_elements);
    formDataSend.append("potential_obstacles", formData.potential_obstacles);
    formDataSend.append("immediate_actions", formData.immediate_actions);
    formDataSend.append("paymentSuccess", formData.paymentSuccess);

    try {
      setLoading(true);

      const response = await fetch(
        `${config.baseURL}/campus-hub/student-registration/`,
        {
          method: "POST",

          body: formDataSend,
        }
      );
      const data = await response.json();
      console.log("response", response);
      console.log("response 2", data);
      let payment_id = data?.payment?.payment_id;

      console.log(data?.payment?.payment_id);
      let payID = JSON.stringify({ paymentID: payment_id });

      const awaitPayment = async () => {
        try {
          const verifyPayment = await fetch(
            `${config.baseURL}/campus-hub/student-registration/payment_mpesa/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${credentials}`,
              },
              body: payID,
            }
          );

          if (
            verifyPayment?.statusText == "OK" &&
            verifyPayment?.status == 200
          ) {
            updateFormData({ ...formData, paymentSuccess: true });
            onNext();
          } else {
            setPaymentError("Payment failed. Please try again.");
          }
        } catch (error) {
          console.error("Verification Error:", error);
          setPaymentError("Payment verification failed. Please try again.");
        } finally {
          setLoading(false);
        }
      };

      // Wait 30 seconds to verify payment
      setTimeout(awaitPayment, 15000);
    } catch (error) {
      console.error("Payment Error:", error);
      setPaymentError("Payment failed. Please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[] md:w-[25rem] md:h-[] h-auto md:max-w-[28rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto">
      <div className="relative">
        <p
          className="absolute flex justify-center items-center cursor-pointer  right-1 top-1 rounded-full text-center z-100 "
          onClick={closePopup}
        >
          {/* <img src="/cancel.svg" alt="" className="size-4" /> */}
          <img src="/close.svg" alt="" className="size-4" />
        </p>
        <div className="font-poppins pb-6">
          <div className="px-6 pt-10">
            <h2 className="text-2xl text-gray-800 font-poppins-semi-bold mb-3">
              4. Final Step
            </h2>
            {/* Registerartion form */}
            <form action="" className="">
              <h2 className="text-xl font-poppins-semi-bold text-[#05195F] mb-2">
                Registeration fee
              </h2>
              <label htmlFor="fee" className="">
                {/* By continuing you agree to pay a non refundable wHTa Networks
                Campus Hub registeration fee of{" "}
                <span className="text-[#80097B]">$ 15</span> */}
                Pay <span className="font-poppins-semi-bold">KES 1,800</span> to{" "}
                <span className="font-poppins-semi-bold">WHTA NETWORKS</span>{" "}
                for{" "}
                <span className="font-poppins-semi-bold">wHTa Campus Hub</span>
                <p className="py-4">
                  Enter your M-Pesa registered phone number below and click{" "}
                  <span className="font-poppins-semi-bold">Pay Now</span>, then
                  check your mobile phone handset for an instant payment request
                  from M-Pesa.
                </p>
                <p>Enter M-Pesa number</p>
              </label>

              <PhoneInput
                international
                defaultCountry="KE"
                value={paymentNo}
                onChange={handleChange}
                className="border py-3 px-4 w-full rounded-md border-purple-darker border-opacity-10 focus:outline-none "
                style={{ backgroundColor: "#FFF5FE" }}
                // onInput={() => {
                //   phone_number?.length < 11 &&
                //     setPhoneError("Phone number must be atleast 11 digits");
                // }}
                required
              />
              {phoneError && (
                <p className="text-red-500 text-xs mb-3">{phoneError}</p>
              )}
              {paymentError && <p className="text-red-500">{paymentError}</p>}

              <div className="mt-4 w-full flex justify-center">
                <SingleClick
                  buttonClass="9"
                  buttonText={loading ? "Processing..." : "Pay Now"}
                  buttonType="button"
                  onClick={() => handlePay()}
                  disabled={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepFour;
