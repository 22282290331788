import React from 'react';
import { Footer2 } from "../../components/index";


function Footer(props) {
    return (
        <>
            <Footer2 />
        </>
    );
}

export default Footer;
