// src/stores/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const loginUser = createAsyncThunk('auth/loginUser', async ({ email, password }) => {
    try{
        const response = await axios.post('http://localhost:8000/api/login/', { email, password });
        return response.data;}
    catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const verifyOtp = createAsyncThunk('auth/verifyOtp', async ({ email, otp }, { rejectWithValue }) => {
    try {
        const response = await axios.post('http://localhost:8000/api/verify-otp/', { email, otp });
        return response.data; // Assuming { access, refresh, user_role } are returned
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: sessionStorage.getItem('accessToken') || null,
        refreshToken: sessionStorage.getItem('refreshToken') || null,
        userRole: sessionStorage.getItem('userRole') || null,  // Retrieve userRole from sessionStorage
        currentUser: JSON.parse(localStorage.getItem('currentUser')) || null,
        isLoading: false,
        error: null,

    },
    reducers: {
        logout: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.userRole = null;
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('userRole'); 
            sessionStorage.removeItem('refreshToken');
            localStorage.removeItem('currentUser');
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.accessToken = action.payload.access;
                state.refreshToken = action.payload.refresh;
                state.userRole = action.payload.user_role; // Store user_role from response
                sessionStorage.setItem('accessToken', action.payload.access); // Save to sessionStorage
                sessionStorage.setItem('userRole', action.payload.user_role);  // Store userRole in sessionStorage
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Store the error message
            });
    },
});


export const selectCurrentUser = (state) => state.auth.currentUser;
export const { setAccessToken, setCurrentUser, logout } = authSlice.actions;
export default authSlice.reducer;