import React from "react";
import NewPasswordDialog from "../components/Login/createNewpassword";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: 'rgb(89, 0, 80)', // Purple
      },
    },
  });

function Create_New_Password() {
  return (
    <>
    <div className="flex py-20 w-full  bg-purple-50 justify-center  h-screen items-center">
    

        
        <ThemeProvider theme={theme}>
      <NewPasswordDialog />
    </ThemeProvider>
        </div>
    </>  
  );
}

export default Create_New_Password;