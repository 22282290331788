// // src/components/Loader.jsx


// import React from 'react';
// import Loader from 'react-js-loader'

// const Loadercomponent = () => {
//     let color='purple';
//   return (
//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
//     <Loader type="spinner-default" bgColor={color} color={color}  size={100} />
//     </div>
//   );
// };

// export default Loadercomponent;

// src/components/Loader/Loader.jsx
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const GradientCircularProgress = styled(CircularProgress)({
  'svg circle': {
    stroke: 'url(#loader_gradient)',
  },
});

export default function Loadercomponent() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full-screen height
        // width:'100%',
        // zIndex:'1000',
        // position:'relative',
        
      }}
    >
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="loader_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#00ffa3" />
            <stop offset="100%" stopColor="#fd00e4" />
          </linearGradient>
        </defs>
      </svg>
      <GradientCircularProgress size={80} thickness={4} />
    </Box>
  );
}

