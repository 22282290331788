import React from "react";

function CampusCard({ title, image, description }) {
  return (
    <div className="min-w-[150px] max-w-[380px] min-h-[300px] font-poppins flex flex-col gap-4 pt-10 px-8 pb-6 text-sm rounded-lg border-t border-t-gray-200 border-x border-x-gray-300 border-b-2 border-b-gray-300">
      <div className="flex items-center justify-start gap-4 w-full">
        <img src={image} alt={title} className="w-[45%]" />
        <p className="font-poppins-semi-bold">{title}</p>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default CampusCard;
