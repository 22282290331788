import React, { useState, useRef, useEffect } from "react";

// import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";


function TimeRangePicker({ setDuration, setTime }) {
  const [startTime, setStartTime] = useState("14:00");
  const [endTime, setEndTime] = useState("14:05");

  const [showDropdown, setShowDropdown] = useState(false);
  const [timePickerType, setTimePickerType] = useState("");
  const dropdownRef = useRef(null);
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);

  const handleTimeChange = (event) => {
    const time = event.target.value;

    if (timePickerType === "start") {
      setStartTime(time);
      setTime({ start: time, end: endTime });
    } else if (timePickerType === "end") {
      setEndTime(time);
      setTime({ start: startTime, end: time });
    }
  };

  const calculateDuration = (start, end) => {
    if (!start || !end) return 0;

    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    const startTime = new Date(1970, 0, 1, startHour, startMinute);
    const endTime = new Date(1970, 0, 1, endHour, endMinute);

    const diffInMinutes = Math.max((endTime - startTime) / 60000, 0);

    return diffInMinutes;
  };

  useEffect(() => {
    if (startTime && endTime) {
      const durationInMinutes = calculateDuration(startTime, endTime);

      setDuration(`${durationInMinutes}`);

    }
  }, [startTime, endTime, setDuration]);

  const handleClickOutside = (event) => {

    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);

    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const openPicker = (type) => {
    setTimePickerType(type);
    setShowDropdown(true);
    if (type === "start") {
      startInputRef.current?.focus();
    } else {
      endInputRef.current?.focus();
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        type="text"
        value={`${startTime} - ${endTime}`}
        onClick={() => setShowDropdown(!showDropdown)}

        readOnly
        className="w-full text-sm md:text-xl p-2 border rounded bg-purple-f focus:outline-none focus:border-blue-500 pl-7 cursor-pointer"
        placeholder="Select Time Range.."
        style={{
          backgroundImage: 'url("/time.svg")',
          backgroundSize: "1.2rem",
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {showDropdown && (
        <div className="absolute bg-white border rounded shadow-lg z-10 p-4 w-full mt-1">
          <div className="mb-4">
            <label className="block text-sm mb-1">Start Time</label>
            <input
              ref={startInputRef}
              type="time"
              value={startTime}
              onChange={(e) => {
                handleTimeChange(e);
              }}
              className="w-full text-sm border rounded p-2"
              onFocus={() => openPicker("start")}

            />
          </div>
          <div>
            <label className="block text-sm mb-1">End Time</label>

            <input
              ref={endInputRef}
              type="time"
              value={endTime}
              onChange={(e) => {
                handleTimeChange(e);
              }}
              className="w-full text-sm border rounded p-2"
              onFocus={() => openPicker("end")}

            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TimeRangePicker;
