export const getUnitType = (type) => {
    if (type === "SR") {
        return "Single Room";
    } else if (type === "BS") {
        return "Bed Sitter";
    } else if (type === "OB") {
        return "One Bedroom";
    } else if (type === "TwB") {
        return "Two Bedroom";
    } else if (type === "TrB") {
        return "Three Bedroom";
    } else if (type === "FB") {
        return "Four Bedroom";
    }
};
export const AllTestimonials=
    [
        {
            clientImg: "/Profile.svg",
            clientName:"Maryline Chepkogei",
            clientOccupation:"",
            text:"Enrolling in WHTA networks has been one of the best decisions I have made in my career. \
            I was impressed by depth of their content and their instructors who are not only knowledgeable but also passionate.\
             Through immersive labs, simulated cyber-attacks scenarios and projects I have gained invaluable \
              skills that I could apply in professional settings. Thanks to WHTA Networks I now feel well prepared to embark on a successful career in cyber security.",


        },
        {
            clientImg: "/Profile.svg",
            clientName:" Wycliffe Kamba",
            clientOccupation:"",
            text:"The working environment created there is very conducive not only among the employer and the employees but also between the company and its customers. WHTA network had managed to maintain good business relation with its customers. The services they offer indeed surpass my expectations time and time again. In conclusion I believe WHTA Networks is the best place to be if you’re looking to enhance your skills, also to anyone seeking for high quality services.",


        },
        {
            clientImg: "/Profile.svg",
            clientName:"Sandra Chelangat",
            clientOccupation:"Student",
            text:"The bootcamp was truly an outstanding learning opportunity. First and foremost, I want to commend the organizers and instructors for putting together such a comprehensive and engaging program. The content was exceptionally well-designed, covering a wide range of topics in cyber security with depth and clarity. Every aspect of the training was meticulously crafted to provide valuable insights and practical skills. I would highly recommend WHTA networks.",


        },
        {
            clientImg: "/Profile.svg",
            clientName:"Simon Maina",
            clientOccupation:"",
            text:"I wholeheartedly recommend WHTA networks to any organization or individual seeking highly skilled professionals with exceptional dedication, a collaborative spirit, and a passion for excellence. WHTA contribution to my cybersecurity journey have consistently exceeded expectations, and I have no doubt they will continue to thrive and make significant contributions to more people’s lives.",
        }
    ]

export const Cardcourses=
    [
    {
        image: "/SecurityRiskImage.jpg",
        title: "Cybersecurity Strategy and Risk Mitigation",
        skills: "Covers risk mitigation, legal and regulatory issues, professional ethics, security architecture...",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 35,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'cybersecurity'
        
    },
    {
        image: "/cryptography.jpg",
        title: "Cryptography for Modern Security",
        skills:"Covers symmetric encryption, symmetric algorithms, asymmetric encryption, asymmetric....",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 18,500",
        level: "Advanced",
        duration: "2 months",
        certificate: "Certificate",
        type: 'cybersecurity'
    },
    {
        image: "/penetrationtesting.jpg",
        title: "Mastering the Art of Penetration Testing",
        brochureUrl: "/MODULE  MASTERING THE ART OF PENETRATION TESTING (1).pdf",
        skills: "Covers reconnaissance, scanning and enumeration, sniffing and evasion,  social engi.....",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 35,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'cybersecurity'
    },
    {
        image: "/linuxandwindowsfundamentals.jpg",
        title: "Operating Systems Mastery",
        skills: "Covers command line and file systems",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 5,000",
        level: "Advanced",
        duration: "3 weeks",
        certificate: "Certificate",
        type: 'cybersecurity'
    },
    {
        image: "/networksecurity.jpeg",
        title: "Network Security Essentials",
        brochureUrl: "/MODULE  MASTERING NETWORK SECURITY ESSENTIALS (1).pdf",
        skills: "Cover network architecture ,Identification and mitigation of network attacks and Network defense strategies ",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 35,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'cybersecurity'
    },
    
    {
        image: "/Blockchain.jpg",
        title: "Exploring Blockchain Applications",
        skills:"Covers blockchain platforms and frameworks, smart contract development, decentralized appl..",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 45,500",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: "emergingtechnologies"
        
    },
    
    {
        image: "/BackendDev.jpg",
        title: "Mastering Backend Technologies",
        brochureUrl: "/MODULE  MASTERING BACKEND TECHNOLOGIES.pdf",
        skills:"Covers Django, Node.js, Spring Boot, DBM, RESTful API Development, Web servers and deployment.....",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 45,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: "softwaredevelopment"
        
    },
    
    
    
    {
        image: "/beginnerfrontend.jpg",
        title: "Mastering Frontend Technologies",
        brochureUrl: "/MODULE  MASTERING FRONTEND TECHNOLOGIES (1).pdf",
        skills: "Covers HTML5 and CSS3, JavaScript, React, Vue, Angular, version control with git, API integration....",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 40,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'softwaredevelopment'
    },
    

]

export const SecondCardcourses=

    [  {
        image: "/UIUX.jpg",
        title: "The Art of UI/UX Design",
        brochureUrl: "/MODULE  THE ART OF  UIUX DESIGN.pdf",
        skills: "Covers user research and analysis, information architecture, wireframing and prototyping, visual..",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 35,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'softwaredevelopment'
    },
        
        {
        image: "/regulatory.jpeg",
        title: "Regulatory Compliance Demystified",
        brochureUrl: "/MODULE  GOVERNANCE REGULATORY AND COMPLIANCE.pdf",
        skills: "Covers Governance and risk management principles and Compliance frameworks and regulatory standards",
        profileIcon: "/Profile.svg",
        academy: "Academy",
        price: "Ksh 35,000",
        level: "Advanced",
        duration: "4 months",
        certificate: "Certificate",
        type: 'cybersecurity'
    },
        
        {
            image: "/FullstackDev.jpg",
            title: "Full Stack Development Essentials",
            brochureUrl: "/MODULE  FULL STACK DEVELOPMENT ESSENTIALS.pdf",
            skills:"Covers both frontend and backend technologies",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 70,000",
            level: "Advanced",
            duration: "8 months",
            certificate: "Certificate",
            type: "softwaredevelopment"
            
        },
        {
            image: "/machinelearning.jpg",
            title: "Machine Learning Essentials ",
            skills:"Covers Supervised and unsupervised learning, NLP, Image manipulation, Deep learning , Reinfor...",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 45,000",
            level: "Advanced",
            duration: "4 months",
            certificate: "Certificate",
            type: "emergingtechnologies"
            
        },
        {
            image: "/digitalforensics.jpeg",
            title: "Art of Digital Forensic Analysis",
            brochureUrl: "/MODULE  ART OF DIGITAL FORENSICS ANALYSIS.pdf",
            skills: "Covers Digital evidence acquisition and analysis and Chain of custody management.",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 35,000",
            level: "Advanced",
            duration: "4 months",
            certificate: "Certificate",
            type: 'cybersecurity'
        },
        {
            image: "/clousecurity.jpeg",
            title: "Cloud Security Mastery",
            skills: "Covers Cloud security fundamentals",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 35,000",
            level: "Advanced",
            duration: "4 months",
            certificate: "Certificate",
            type: 'cybersecurity'
        },
        {
            image: "/IOT.jpg",
            title: "IoT Innovation ",
            skills:"Covers IoT architecture and protocols, sensor integration and data acquisition, IoT platforms a...",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 45,500",
            level: "Advanced",
            duration: "4 months",
            certificate: "Certificate",
            type: "emergingtechnologies"
            
        },
        {
            image: "/mobiledevelopment.jpeg",
            title: "Mobile App Development ",
            brochureUrl: "/MODULE  MOBILE APP DEVELOPMENT.pdf",
            skills: "Covers Mobile application development frameworks (e.g., Swift for iOS,Kotlin/Java for Android) and User interface design",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 35,000",
            level: "Advanced",
            duration: "4 months",
            certificate: "Certificate",
            type: 'softwaredevelopment'
        },
        {
            image: "Devops.jpg",
            title: "DevOps Revolution ",
            skills:"Covers CI/CD, IaC, microservices, configuration management, monitoring and logging, security ...",
            profileIcon: "/Profile.svg",
            academy: "Academy",
            price: "Ksh 50,000",
            level: "Advanced",
            duration: "5 months",
            certificate: "Certificate",
            type: "softwaredevelopment"
            
        },
]

export const Jobs = [
    {title: 'Frontend Developer', type: 'Remote', link: '#'},
    {title: 'UI/UX Designer', type: 'Remote', link: '#'},
    {title: 'Backend Developer', type: 'Remote', link: '#'},
    {title: 'DevOps Engineer', type: 'Remote', link: '#'},
    {title: 'Network Security Analyst', type: 'Remote', link: '#'},
    {title: 'Cloud Security Architect', type: 'Remote', link: '#'},
    {title: 'Penetration Tester', type: 'Remote', link: '#'},
    {title: 'Cybersecurity Risk Analyst', type: 'Remote', link: '#'},
    {title: 'Machine Leaning Engineer', type: 'Remote', link: '#'},
    {title: 'Mobile Apps Developer', type: 'Remote', link: '#'}

]