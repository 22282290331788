/* eslint-disable no-unused-vars */
import React from "react";

function Service(service, index) {
  return (
    <div
      className="bg-white-a rounded-3xl py-5 w-full h-auto flex items-center justify-center shadow-xl"
      key={index}
    >
      <div className="h-5/6 flex flex-col justify-center items-center">
        <div className=" flex items-center justify-center w-full">
          <img src={service.icon} alt={service.title} className="" />
        </div>
        {/*
        <h3 className="text-purple-normal-active text-xl text-center font-poppins-bold">
          {service.title}
        </h3>
        <p className="text-black-a text-center font-poppins-semi-bold">
          {service.description}
        </p>*/}
      </div>
    </div>
  );
}

export default Service;
