import React, { useState } from "react";
import { SingleClick } from "../../micros/index";
import { CheckboxesCard } from "../../micros/index";
function BannerSection({ backgroundImage, desc, btnText = null }) {
  // const [isPopupOpen, setPopupOpen] = useState(false);

  // const openPopup = () => {
  //   setPopupOpen(true);
  // };

  // const closePopup = () => {
  //   setPopupOpen(false);
  // };
  return (
    <div className=" relative flex flex-col items-center justify-center max-w-8xl ">
      <p className="leading-tight text-[1.2rem] sm:text-[1.5rem] lg:text-[2rem] xl:text-[2.5rem] text-white italic sm:p-5  absolute text-center z-10 line-clamp-3">
        {desc}
      </p>
      {btnText ? (
        <div className="absolute z-10 bottom-1 lg:bottom-6 xl:bottom-12">
          <a href="/contact-us">
            <SingleClick
              buttonClass="9"
              buttonText={btnText}
              buttonType="button"
            />
          </a>
        </div>
      ) : (
        ""
      )}
      {/* semi-transparent dark overlay */}
      <div className="relative ">
        <img
          src={backgroundImage}
          alt={backgroundImage}
          className="bg-cover bg-center min-h-[9rem]"
        />
        <div className="absolute inset-0 bg-black opacity-60 "></div>
      </div>
      {/* {isPopupOpen && (
        <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
          <div className="bg-white mt-0 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[42rem]  rounded-lg max-h-[80vh]    overflow-y-auto">
            {/* Add your popup content here */}
            {/* <CheckboxesCard onClose={closePopup} />
          </div>
        </div>
      )} */} 
    </div>
  );
}

export default BannerSection;
