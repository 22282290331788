import React from "react";
import AdvantagesComponent from "../../../components/sections/industries/healthcare/advantages/Advantages";
import SoftwareComponent from "../../../components/sections/industries/healthcare/advantages/software";
import { Cybersecurity, retailCybersecurity } from "../../../components/micros/utilities/dataUtilCybersecurity";
import CybersecurityComponent from "../../../components/sections/industries/healthcare/advantages/cybersecurity";

import IndustriesHerosection from "../../../components/sections/industries/healthcare/herosection/IndustriesHero";
import { retailSoftware } from "../../../components/micros/utilities/dataUtilSoftware";




function Retail_() {
  return (

    <div className="w-full h-full " >
     <IndustriesHerosection maintitle={'Revoultionizing Retail with Innovative Solutions at wHTa Networks'} title={'Retail'} backgroundimage={'/icons/Forensics.png'}/>
    <CybersecurityComponent data={retailCybersecurity}/>
     <SoftwareComponent data={retailSoftware}/>

      <AdvantagesComponent/>

    </div>
  );
}

export default Retail_;
 