import React from "react";
import { Navbar1 } from "../../components/index.jsx";

const Header = () => {
  return (
    <div>
      <Navbar1 />
    </div>
  );
};

export default Header;
