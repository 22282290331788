const ContactUs = () => {
  return (
    <div className="min-h-screen w-full">
      <h1 className="text-4xl font-bold text-center text-[#1C0A6D] mt-24 mb-4">
        Contact Us
      </h1>
      <p className="text-center font-semibold">Our Team would love to hear from you!</p>
      <div  className="flex flex-row gap-4 mx-10 w-3/3 pt-14 ">
        <div className="flex flex-col m-2 items-center justify-center w-1/3 p-10 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100">
        <img src="/tel.svg" alt=""  className="w-6 h-6 mb-8"/>
        <span className="text-[#1C0A6D]">+(254) 782 499 113</span>
        </div>
        <div className="flex flex-col m-2 items-center justify-cente w-1/3 p-10 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100">
        <img src="/whatsapp.svg" alt="" className="w-6 h-6 mb-8" />
        <span className="text-[#1C0A6D]">+(254) 782 499 113</span>
        </div>
        <div className="flex flex-col m-2 items-center justify-center w-1/3 p-10 transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-100">
        <img src="/mail.svg" alt="" className="w-6 h-6 mb-8" />
        <span className="text-[#1C0A6D]">hello@whtanetworks.com</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
