import React from 'react'

function EventHero() {
  return (
    <>
        <div className='relative min-h-[20rem] '>
            <img src="/icons/event-bg.png" alt="event img" className='w-full min-h-[20rem] h-full'/>
            <div className='absolute top-20  xl:top-60 right-2 lg:right-20 text-white-a w-[30re] lg:w-[40rem] xl:w-[60rem] text-center'>
                <h2 className=' text-[2rem] sm:text-[3rem] lg:text-[4rem] xl:text-[5rem] font-poppins-bold '>Great Events Start Here</h2>
                <p className='font-poppins text-xl lg:text-3xl text-wrap '>"Explore groundbreaking tech events shaping tomorrow's software landscape. Join us at the forefront of innovation!"</p>
            </div>
        </div>
    
    </>
  )
}

export default EventHero