import Hero from "./Hero";
import ProtectData from "./Protectdata";
import Solutions from "./Solutions";

const Cybersecurity = () => {
  return (
    <div>
      <Hero />
      <ProtectData />
      <Solutions />
    </div>
  );
};
export default Cybersecurity;
