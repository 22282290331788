import React from "react";

const SolutionCard = ({ icon, title, description }) => (
  <div className="bg-white p-4 rounded shadow max-w-xs w-full flex flex-col items-start transform transition-transform duration-300 hover:scale-105">
    <div className="flex items-center gap-2">
      <div className="bg-purple-dark-hover rounded-full flex items-center justify-center w-12 h-12 p-2">
        <img src={icon} alt={title} className="w-8 h-8 object-contain" />
      </div>
      <h4 className="text-purple-normal-active text-2xl  text-center font-quicksand-bold">
        {title}
      </h4>
    </div>
    <div className="py-6">
      <p className="text-black-a text-left font-poppins">{description}</p>
    </div>
  </div>
);

export default SolutionCard;
