import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Ethical Hacking",
    "Wireless network penetration testing",
    "Web application security testing techniquest",
    "Packet analysis",
    "Network protocols",
    "Penetration testing tools",
    "Burp Suite",
    "Metasploit",
    "Reverse engineering",
    "Exploit development",
    "Knowledge of operating systems",
    "Bash",
    "Python",
    "Social engineering tests",
    "Phishing Simulations",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "mentorship Skills",
    "Leadership skills",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Conduct penetration tests using ethical hacking techniques and industry-standard tools such as Burp Suite, Metasploit, and Nmap to identify vulnerabilities and potential exploits.",
    "Utilize your expertise in common vulnerabilities and attack vectors to assess the security posture of our clients' systems and networks.",
    "Perform web application security testing, including identifying and exploiting vulnerabilities in web applications.",
    "Execute wireless network penetration testing to evaluate the security of our clients' wireless infrastructure.",
    "Conduct social engineering tests and phishing simulations to assess employee awareness and susceptibility to social engineering attacks.",
    "Develop and execute exploit scripts using scripting languages like Python and Bash to validate identified vulnerabilities.",
    "Document findings and recommendations using reporting tools, providing detailed reports to clients.",
    "Collaborate with cross-functional teams to remediate identified vulnerabilities and enhance overall security posture.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Information Security, or a related field (or equivalent experience).",
    "Previous experience in penetration testing or related roles with a proven track record of identifying and mitigating security risks.",
    "Certifications such as Certified Ethical Hacker (CEH), Offensive Security Certified Professional (OSCP), or GIAC Penetration Tester (GPEN) are advantageous.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Penetration Tester"}
          Description={
            "We are seeking a talented and motivated Penetration Tester to join our team. As a Penetration Tester, you will play a crucial role in evaluating our clients' cybersecurity posture by identifying and exploiting vulnerabilities using ethical hacking techniques and cutting-edge tools. This is an exciting opportunity to work on challenging projects, collaborate with industry experts, and make a meaningful impact in the cybersecurity field."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
