import React from 'react'

const Testimony = ({ testimonials }) => {
  return (
    <>
    {
      testimonials.map((testimony, index) => (
        <div className='bg-white  xl:w-[35rem] md:w-[31rem]  p-2 rounded-l-3xl rounded-br-3xl justify-between'key={index}>
         <p className='text-sm h-auto md:h-6/8 flex pt-4 items-center justify-between'>
            <span className='text-[3rem] p-1 '>“</span><br/>
            <span className=' font-poppins text-[1rem] md:text-[1.2rem] mx-auto bg-white mt-4'>{testimony.text}</span>
            <br/>
            <span className='text-[3rem] p-1'>”</span>
          </p>

    
          <div className='flex h-auto md:h-2/8  flex-row  items-end p-2 mt-4 md:mt-10  '>
          <img src={testimony.clientImg} alt="profile-icon" className='w-10 rounded-full'/>
          <div className='flex flex-col text-[0.9rem] pl-1 md:text-[1.2rem] font-poppins-bold justify-between'>
            <h5 >{testimony.clientName}</h5>
           {/*  <h8>{testimony.clientOccupation}</h8> */}
            </div>
            
          </div>
          
        </div>
      ))
    }

    </>
  )
}

export default Testimony
