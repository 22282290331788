

const Hero = () => {
  

    return (
      <div className="min-h-screen flex flex-col gap-6 md:gap-16 mt-20 md:mt-40  md:mx-24 md:flex-row">
        <div className=" w-full md:w-2/3 flex flex-col gap-6 p-6 md:px-4">
          <h1 className="text-4xl font-bold  flex flex-col m-1">
          <span>Protect Your Organization</span>
  <span  className="text-[#9C1583]">with Advanced Cyber Security</span>
  <span className="text-[#1C0A6D]">Solutions</span>
          </h1>
  
          <p className="md:text-lg">
          Our mission is to safeguard businesses like yours against 
  evolving cyber threats. With expert penetration testing and 
  tailored threat intelligence solutions, we help organizations 
  identify vulnerabilities, mitigate risks, and achieve compliance with ease
          </p>
          <div>
            <a href="/contact-us">
            <button
              className="bg-gray-200 text-[#9C1583] font-semibold p-1 rounded"
              
            >
              Report Data Breach        {/* <a href="#pricing">Try a Demo</a> */}
            </button>
            </a>
            
          </div>
        </div>
        <div className=" md:ml-10 mx-6 mt-6 ">
          <img src="/lockicon.svg" className="" alt=" hero section image" />
        </div>
      </div>
    );
  };
  
  export default Hero;
  