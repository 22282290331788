import React, { forwardRef } from "react";

const Messagecard = forwardRef(
  (
    {
      sender_id,
      time,
      message,
      onReply,
      isReplying,
      setIsReplying,
      replyingTo,
      ...props
    },
    ref
  ) => {
    // const reply=replyingTo?
    const classname = replyingTo ? "pt-[28px]" : "pt-[5px]";
    return (
      <>
        {/* {replyingTo && (
          <div className="w-[90%] h-[20px] ml-[3%] z-10 absolute top-[-15px] flex gap-2 text-xs  justify-end items-center bg-gray-100 p-2 mb-2 rounded-lg">
            <img src="/chat_user.svg" alt="User" className="size-4" />
            <p className="text-[#7D0779] w-[] ">{replyingTo?.sender_id}</p>
            <p className="text-xs max-w-[40%] truncate-text">
              {replyingTo?.message}
            </p>
            <img
              src="/cancel.svg"
              alt="cancel"
              className="size-[10px]"
              onClick={() => setIsReplying(false)}
            />
          </div>
        )} */}
        <div
          {...props}
          ref={ref}
          className={`relative flex items-start gap-[8px] pl-[8px] py-[5px] ${classname} mb-2 text-[16px] border border-gray-400 rounded-[10px] hover:grayscale-4 hover:bg-gray-300 messagecard`}
        >
          {replyingTo && (
            <div className="w-[90%] h-[20px] ml-[] absolute top-[4px] flex gap-2 text-xs  justify-start items-center p-2 mb-2 rounded-lg">
              <img src="/reply.svg" alt="" className="size-6" />
              <img src="/chat_user.svg" alt="User" className="size-4" />
              <p className="text-[#7D0779] w-[] ">{replyingTo?.sender_id}</p>
              <p className="text-xs max-w-[40%] truncate-text">
                {replyingTo?.message}
              </p>
              {/* <img
                src="/cancel.svg"
                alt="cancel"
                className="size-[10px]"
                onClick={() => setIsReplying(false)}
              /> */}
            </div>
          )}
          <img
            src="/chat_user.svg"
            alt=""
            className="mt-0 size-14"
            width="100%"
          />
          <div className="pt-0 pr-[6px] w-full">
            <p className="flex justify-between pr-[5px] w-full">
              <span className="text-[#7D0779]">{sender_id}</span>
              <span className="text-xs">{time}</span>
            </p>
            <p className="text-[#2A26EF] leading-[18px] text-[14px]">
              {message}
            </p>
          </div>
          <div
            className="rounded-full cursor-pointer absolute top-[-5px] right-0 m-[8px] size-8 hidden hover:block reply-icon"
            onClick={() => onReply({ sender_id, time, message })}
          >
            <img
              src="/reply.svg"
              alt="Reply"
              title="Reply"
              className="transform rotate-180 "
            />
          </div>
        </div>
      </>
    );
  }
);


export default Messagecard;
