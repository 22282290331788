import React from "react";
import ServicesHero from "../servicePage/hero/ServicesHero";
import PortfolioOne from "./PortfolioOne";

function Portfolio() {
  return (
    <div>
      <ServicesHero
        serviceTitle="Portfolio and Case Studies"
        serviceTitleImg="/icons/webdevTittle.png"
        serviceDescription1="We are experts in software integration, ecommerce solutions, IT infrastructure, help desk services, web design and development, scalable technology solutions, and so much more!"
        serviceDescription2=""
        serviceImg="/serviceHero2Objects.svg"
      />
      <PortfolioOne />
    </div>
  );
}

export default Portfolio;
