import React from "react";

function AboutImg() {
  return (
    <>
      <div className="w-full sm:w-1/2 flex items-center">
        <img src="/icons/about.svg" alt="about img" className="h-full" />
      </div>
    </>
  );
}

export default AboutImg;
