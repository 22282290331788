const ProtectData = () => {
  return (
    <div className="w-full min-h-screen ">
      <div className="text-center items-center justify-center">
        <h1 className="text-xl lg:text-2xl font-bold mb-12 text-right mr-16">
          How wHTa can Protect Your Data?
        </h1>
      </div>
      <div className="mt-12 pb-4 ">
        <div className=" flex flex-row gap-0  ">
          <img src="/files_icon.svg" className="w-1/5 ml-24 transition duration-300 ease-in-out transform hover:scale-105" />

          <div className="w-1/3 flex flex-col ml-16 transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-[#1C0A6D] text-xl">Assessment</span>
            <span>We analyze your current security infrastructure.</span>
          </div>
          <div className="w-1/3"></div>
        </div>
        <div className=" flex flex-row gap-0  mb-10">
          <div className="w-1/3  "></div>
          <img src="/cloudicon.svg" className="w-1/5 transition duration-300 ease-in-out transform hover:scale-105" />

          <div className="w-1/3 flex flex-col ml-4 transition duration-300 ease-in-out transform hover:scale-105">
            <span className="text-[#1C0A6D] text-xl">Testing</span>
            <span>
              We Conduct comprehensive penetration testing to identify gaps.
            </span>
          </div>
        </div>
        <div className="mt-2 flex flex-row gap-0 ">
          <div className="w-1/3 flex flex-col ml-4"></div>

          <div className="w-1/3 flex flex-col ml-4 mt-8 transition duration-300 ease-in-out transform hover:scale-105 ">
            <span className="text-[#1C0A6D] text-xl">Compliance Support</span>
            <span>Ensure adherence to regulatory standards seamlessly.</span>
          </div>
          <img src="/maintenance.svg" className="ml-16 w-1/5 transition duration-300 ease-in-out transform hover:scale-105" />
        </div>
      </div>
    </div>
  );
};

export default ProtectData;
