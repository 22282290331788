import React, { useState } from "react";
import { SingleClick } from "../../../micros";
import DatePicker from "./Calendar";
import { Input } from "../../../micros/index";
import emailjs from "@emailjs/browser";
import MessageTxt from "../../../major/messagesent/MessageTxt";
import { credentials } from "../../../../auth";
import { config } from "../../../../auth";
import TimeRangePicker from "./TimeRangePicker";
import PhoneInput from "react-phone-number-input";

const MeetingForm = ({ onClose }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setmessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState({ start: "", end: "" });
  const [duration, setDuration] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("overflow-y-hidden");
  } else {
    document.body.classList.remove("overflow-y-hidden");
  }

  const handleDateChange = (selectedDate) => {
    // Updating the state with the selected date
    setDate(selectedDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDate = date.toISOString().split("T")[0];
    const formData = {
      name: fullName,
      phone_number: phoneNumber,
      email,
      message,
      date: formattedDate,

      time: time.start,

      duration,
    };
    console.log(formData);
    try {
      const response = await fetch(
        `${config.baseURL}/careers/individual-bookings/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("Here is your form data", formData);
      let da = JSON.stringify(formData);
      console.log("JSON", da);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);

      // Reset form fields
      setFullName("");
      setEmail("");
      setmessage("");
      setDate("");
      setPhoneNumber("");
      setTime({ start: "", end: "" });
      setDuration("");
      toggleModal();
    } catch (error) {
      console.error("Error:", error);
    }

    // send data with emailjs
    const serviceId = "service_i2t3886";
    const templateId = "template_kbgpofr";
    const publicKey = "ONqH6-Z93cZfem7Rj";

    const templateParams = {
      from_name: fullName,
      from_email: email,
      to_name: "wHTa Networks",
      message: `Individual Reservation.. Message: ${message}, Date: ${date}, Time: ${time}, Duration: ${duration}.`,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((res) => {
        console.log("Email sent successfully: ", res);
      })
      .catch((err) => {
        console.error("Error sending email, ", err);
      });
    setFormSubmit(!formSubmit);
  };

  return (
    <>
      {formSubmit ? (
        <div className="items-center justify-center flex">
          <MessageTxt onClose={onClose} />
        </div>
      ) : (
        <div className="max-w-screen-md mx-auto w-full p-0 md:p-4 rounded-lg bg-white relative">
          <div className="text-center pt-16 pb-12">
            <p className="xl:text-[3.125rem] lg:text-[3.125rem] md:text-[3.125rem] text-[1.8rem] font-poppins-semi-bold">
              Book a Meeting
            </p>
            <p className="xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.25rem] text-[1rem] text-gray-400 font-poppins">
              Let’s connect and work together, with just one click.
            </p>
          </div>

          <div
            className="absolute top-0 right-0 float-right m-2 md:mt-[60px]  text-gray-600 cursor-pointer"
            onClick={onClose}
          >
            <svg
              className="h-10 w-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <form onSubmit={handleSubmit} className="mt-4 ">
            <div className="w-full  ">
              <div className="pb-4 ">
                <Input
                  type="text"
                  placeholder="full-name"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={fullName}
                  onChange={setFullName}
                  copyPaste={false}
                />
              </div>
              <div className="pb-4">
                <PhoneInput
                  international
                  defaultCountry="KE"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="border py-3 px-4 w-full rounded focus:outline-none "
                  style={{ backgroundColor: "#FFF5FE" }}
                  required
                />
              </div>
              <div className="pb-4">
                <Input
                  type="email"
                  placeholder="email"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={email}
                  onChange={setEmail}
                  copyPaste={true}
                />
              </div>
              <div className="pb-4">
                <Input
                  type="text"
                  placeholder="Drop a message"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={message}
                  onChange={setmessage}
                  copyPaste={false}
                />
              </div>
            </div>
            {/* Additional fields with icons */}
            <div className="flex flex-row-reverse xl:gap-14 lg:gap-14 md:gap-10 gap-2 w-full">
              <div className="icon-input mt-4 w-1/2  flex items-center text-xl">
                <input
                  type="text"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="w-full text-sm md:text-xl p-2 border rounded bg-purple-f focus:outline-none focus:border-blue-500 pl-7"
                  placeholder="Duration in Minutes"
                  style={{
                    backgroundImage: 'url("/duration.svg")',
                    backgroundSize: "1.2rem",
                    backgroundPosition: "left center  ",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>

              <div className="icon-input mt-4 w-1/2 flex items-center justify-between text-xl">
                <TimeRangePicker setDuration={setDuration} setTime={setTime} />
              </div>
            </div>

            <div className="flex flex-row justify-center  xl:gap-14 lg:gap-14 md:gap-10 gap-2  w-full">
              <div className="icon-input mt-4 w-1/2 p-2 flex items-center  md:px-0 px-4">
                <DatePicker onDateChange={handleDateChange} />
              </div>
            </div>

            <div className="flex items-center justify-center pt-10 pb-10">
              <SingleClick
                buttonClass="10"
                buttonText="Book"
                buttonType="submit"
              />
            </div>
            {modal && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                <div
                  onClick={toggleModal}
                  className="fixed inset-0 w-screen h-screen"
                >
                  <div className="box sm:w-2/3 lg:w-1/2 xl:w-2/5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 leading-loose bg-white py-3.5 px-3.5 shadow shadow-slate-500 rounded max-w-600 min-w-300 w-full h-80 text-center">
                    <h2 className="text-4xl font-poppins-bold mt-6 tracking-wider">
                      Messsage Sent!
                    </h2>
                    <p className="font-poppins text-md mt-4">
                      Our team shall contact you soon
                    </p>
                    <img
                      src="/icons/check.svg"
                      alt="check img"
                      className="w-15 h-16 mx-auto mt-6"
                    />
                    <button
                      className="absolute py-2 px-1.5 top-0 right-4 text-xl text-slate-400"
                      onClick={toggleModal}
                    >
                      x
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default MeetingForm;
