import React from "react";
import { ServicesHero } from "../../../components/index";
import {
  WhyProdDesign,
  ProductDesignService,
} from "../../../components/major/index";
import { BannerSection } from "../../../components/micros/index";

function ProductDesign() {
  return (
    <>
      <ServicesHero
        serviceTitle="Product Design"
        serviceTitleImg="/icons/Forensics.png"
        serviceDescription1="Our product design services are focused on helping businesses bring their ideas to life, from concept to reality, by combining creativity, innovation, and user-centered design principles."
        serviceDescription2=""
        serviceImg="/icons/Product Design.svg"
      />

      <WhyProdDesign />
      <div className="flex items-center justify-center ">
        <BannerSection
          backgroundImage="/icons/pentestBanner1.png"
          desc="Unlocking the secrets of the digital realm, one bit at a time."
          btnText="Kickstart Your Project"
        />
      </div>

      <ProductDesignService />
    </>
  );
}

export default ProductDesign;
