import React from "react";

import { AboutImg, AboutText } from "../../../major/index";

function AboutUs() {
  return (
    <>
      <div className=" flex flex-col items-center justify-center w-full px-8 sm:px-12 lg:px-24 xl:px-32 ">
        {/* fix bg */}
        <div className="bg-element3 bg-cover bg-center h-full  bg-no-repeat w-full flex items-center justify-center  ">
          <div className="py-24 flex flex-col sm:flex-row  w-full gap-8 sm:gap-16 lg:gap-24 xl:gap-36 lg:justify-between max-w-8xl ">
          <AboutImg />
          <AboutText />
        </div>
        </div>
        
      </div>
    </>
  );
}

export default AboutUs;
