/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { SingleClick } from "../../micros/index";
import magnify from "/icons/magnifying.svg";
import suitcase from "/icons/suitcase.svg";
import coin from "/icons/coin.svg";
import { Jobs } from "../../../components/micros/utilities/dataUtil";
import Jobcards from "./jobcards";

function AvailableJobs() {
  const [filteredJobList, setFilteredJobListings] = useState(null);
  const [searchType, setSearchType] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [error, setError] = useState("");

  const handleSearch = () => {
    if (!searchTitle.trim() || !searchType.trim()) {
      setError("Enter a valid job title and type");
      alert("Enter a valid job title and type");
      return;
    }

    const filtered = Jobs.find((job) => {
      const titleMatch = job.title
        .toLowerCase()
        .includes(searchTitle.toLowerCase());
      const typeMatch = job.type
        .toLowerCase()
        .includes(searchType.toLowerCase());
      return titleMatch && typeMatch;
    });
    console.log("filtered jobs", filtered);
    setFilteredJobListings(filtered);
    setError("");
  };

  const handleTitleFilterChange = (e) => {
    setSearchTitle(e.target.value);
    console.log("title", e.target.value);
  };

  const handleTypeFilterChange = (e) => {
    setSearchType(e.target.value);
    console.log("type", e.target.value);
  };

  return (
    <>
      <div className="mx-auto max-w-md flex flex-col justify-center items-center relative mt-8">
        <div className="flex flex-col w-screen  md:px-10">
        <h2 className="pl-4 font-poppins italic pt-2 text-2xl sm:text-3xl xl:text-4xl mt-[4rem] sm:mt-[6rem] xl:mt-[9rem] mb-6">
              {" "}
              <span className="text-purple-dark-hover">
                Join Our Innovative Team at wHTa Networks</span>
            </h2>
                <p className="md:text-lg text-sm p-3 pb-4 text-black font-poppins italic">Are you ready to shape the future of technology?
                   At wHTa Networks, we’re looking for passionate, creative, and driven individuals to join our dynamic team.
                    Explore exciting career opportunities, push the boundaries of innovation, and make a meaningful impact on 
                    a global scale. Your journey to success starts here! </p>
              
        </div>
        <div className="flex md:flex-row flex-col  ">
        
        <div className="relative h-11 flex-1 flex-row p-2">
          <div className="rounded-tl   rounded-bl border border-purple-darker border-opacity-10 p-2 pl-10 sm:pl-12">
            <input
              className="w-full"
              placeholder="Job Title"
              value={searchTitle}
              onChange={handleTitleFilterChange}
            />
            <img
              src={magnify}
              alt="Search Icon"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="relative h-11 flex-1 p-2">
          <div className="border border-purple-darker border-opacity-10  p-2 pl-10 sm:pl-12">
            <input
              className="w-full"
              placeholder="Job Type"
              value={searchType}
              onChange={handleTypeFilterChange}
            />
            <img
              src={suitcase}
              alt="Suitcase Icon"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="flex bg-white md:item-start md:justify-start items-center justify-center">
        <SingleClick
          buttonClass="13"
          buttonText="Search"
          buttonType="button"
          onClick={handleSearch}
        />
        </div>
      </div>
      </div>
      <div>
        {filteredJobList ? (
          <div className="max-w-screen-xl mx-auto m-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-16 mx-5">
              <div key={filteredJobList.title}>
                <div className="bg-white-a p-4 rounded-md border border-gray-300">
                  <div className="flex items-center mb-2">
                    <img
                      src={suitcase}
                      alt="Suitcase Icon"
                      className="w-4 h-4 mr-2"
                    />
                    <h2 className="text-lg font-semibold">
                      {filteredJobList.title}
                    </h2>
                    <img
                      src={coin}
                      alt="Coin Icon"
                      className="w-4 h-4 mr-2 ml-auto"
                    />
                    <h2 className="text-lg">Internship</h2>
                  </div>
                  <div className="h-4"></div>
                  <div className="flex justify-between">
                    <SingleClick
                      buttonClass="12"
                      buttonText={filteredJobList.type}
                      buttonType="button"
                    />
                    <SingleClick
                      buttonClass="14"
                      buttonText="Send"
                      buttonType="button"
                    />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        ) : (
          <Jobcards />
        )}
      </div>
    </>
  );
}

export default AvailableJobs;
