import React, { useState } from "react";
import PropTypes from "prop-types";

function Select({ value, onChange, selectClass, children, ...props }) {
  //   const [value, setValue] = useState("");

  if (selectClass === "1") {
    selectClass =
      "w-full bg-white border border-[#7D0779] text-md rounded-[10px] pl-[15px] py-[7px] block appearance-none pr-8 leading-tight ";
  } else if (selectClass === "2") {
    selectClass =
      "w-full bg-white border-none text-sm rounded-[10px] pl-[15px] py-[10px] block appearance-none pr-8 leading-tight";
  }
  return (
    <div className="flex gap-4 relative">
      <select
        className={selectClass}
        onChange={onChange}
        value={value}
        {...props}
      >
        {children}
      </select>
      <div class="absolute inset-y-0 right-2 flex items-center px-2 pointer-events-none">
        <img src="/dropdown.svg" alt="" />
      </div>
    </div>
  );
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  selectClass: PropTypes.oneOf(["1", "2"]).isRequired,
};

export default Select;
