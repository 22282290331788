import React from 'react'
import { EventHero, EventCard } from '../../micros/index'

function Eventpage() {
    const events = [
      {
        img: "/icons/bootcamp-event-img.png",
        text: "Boot Camps",
        desc: {
          title: "DevOps Culture",
          date: "June 4, 2024 – July 31, 2024 ",
          location: "Online",
          ticket: 11500
        }
      },
      {
        img: "/icons/webinar-event-img.png",
        text: "Webinars",
        desc: {
          title: "Decoding Social Engineering",
          date: "10-04-2024 ",
          location: "X (@RealWHTA)",
          ticket: 'Free',
          time: '9:00 PM EAT – 10:00 PM EAT'
        }
      },
      {
        img: "/icons/workshop-event-img.png",
        text:"Workshops",
        desc: {
          title: "Unlocking Microservices",
          date: "25-05-2024",
          location: "Hotel Bulleys Thika",
          ticket: 4500,
          time: '10:00 AM EAT – 4:00 PM EAT'
        }
      },
      {
        img: "/icons/seminar-event-img.png",
        text: "Seminars",
        desc: {
          title: "Cybersecurity Strategy and Risk Mitigation",
          date: "19-04-2024  ",
          location: "Google Meet",
          ticket: 1000,
          time: '2:00 PM EAT – 5:00 PM EAT'
        }
      }
  ]


  return (
    <>
      <div className=' mt-24 '>

        <div className='flex justify-center items-center flex-col'>
            <EventHero />
            <h1 className='font-poppins-bold text-center text-3xl  xl:text-[2.6rem] max-w-[72rem] mt-[3rem] sm:mt-[4rem] xl:mt-[6rem]'>A Place to <span className='text-purple-darker'>Learn</span>, Unlearn and <span className='text-purple-darker'>Relearn</span>, forging <span className='text-purple-darker'>new pathways</span> to success.</h1>
        </div>
        <div className=' flex items-center justify-center mb-[10rem] lg:mb-[19rem]'>
            <div className=''>
                <h2 className='pl-4 font-poppins italic text-2xl sm:text-3xl xl:text-4xl mt-[4rem] sm:mt-[6rem] xl:mt-[9rem] mb-6'>Explore Our <span className="text-purple-darker">Categories</span> and Find <span className='text-purple-darker'>YOU:</span></h2>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 '>
                  {
                    events.map((event, index) => (
                      <EventCard {...event} key={index}  />
                    ))
                  }
                </div>

            </div>
        </div>
        
      </div>
    </>
  )
}

export default Eventpage