import React from 'react'
import { WhyChooseUs } from "../../../micros/index";

function WhyDigForensics() {
    const whyusData = [
        {
            title: "Experience and Expertise",
            description:"With years of cybersecurity and digital forensics experience, our team handles complex incidents across industries.",
            icon: "icons/brain.svg",
          },  
        {
            title: "Rapid Response",
            description:"Our expert team is available 24/7 to respond quickly to any cybersecurity issues.",
            icon: "icons/softdev.svg",
          },
        {
            title: "Comprehensive Approach", 
            description: "We use industry best practices to thoroughly investigate security incidents, preserve evidence, and find the root cause.",
            icon: "icons/headset1.svg",
          },
        {
            title: "Legal and Regulatory Compliance",
            description:"Our processes ensure that evidence is collected and handled in a manner that preserves its integrity and admissibility in legal proceedings.",
            icon: "icons/money.svg",
        },
        {
            title: "Communication",
            description:"We keep you updated with regular transparent communication throughout the investigation, informing you of our progress and findings.",
            icon: "icons/money.svg",
        },
      ];
    
      return (
        <div className="w-full bg-purple-f">
          <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
            Why choose us
          </h2>
          <div className='flex items-center justify-center'>
            <div className="flex flex-wrap items-center justify-center lg:justify-between gap-4 pb-[4rem] sm:pb-[6rem] lg:pb-[12rem] ">
                {whyusData.map((whyus, index) => (
                <WhyChooseUs {...whyus} key={index} />
                ))}
            </div>
          </div>
        
        </div>
      );
}

export default WhyDigForensics