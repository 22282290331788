import React from "react";
import { HalfHeroSection } from "../../../micros/index";

function ItHero() {
  return (
    <div className="flex flex-col gap-10 h-auto  w-full p-auto overflow-y-hidden  items-center justify-between sm:justify-around pt-24">
      {/* <HalfHeroSection
        backgroundImage="/itconsulting.webp"
        text="IT Consulting"
      /> */}

      <div className="relative flex flex-col items-center justify-center w-full">
        {/* semi-transparent dark overlay */}
        <div
          className="relative h-[14rem]"
          style={{
            width: "100%",
            backgroundImage: "url('/itconsulting.jpeg')",
            backgroundSize: "100% 200%",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "30%",
          }}
        >
          {/* <img
            src="/itconsulting.jpeg"
            alt="/itconsulting.jpeg"
            className="bg-cover bg-center h-[8rem]"
          /> */}
          <div className="absolute inset-0 bg-black opacity-60"></div>
        </div>
        <h1
          className="text-xl lg:text-[2.5rem] xl:text-[3rem] text-white font-poppins-bold sm:p-5 lg:p-[4.5rem] absolute text-center z-10 whitespace-nowrap"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          IT Consulting
        </h1>
      </div>

      <div className="flex flex-col items-center justify-center w-auto sm:w-[70vw] px-6 sm:px-0">
        <p className="text-[#590050] font-poppins-semi-bold text-center text-xl">
          Our IT Consulting Services
        </p>
        <hr className="w-[25%] h-[5px] bg-[#590050] my-2 rounded-lg" />
        <p className=" my-4 text-sm sm:text-md">
          Our elite IT Consulting team leverages advanced strategies to identify
          and solve potential gaps in your systems. By simulating real-world
          scenarios, we ensure your business is resilient against evolving
          challeges. Safeguard your operations, enhance your efficiency, and
          stay ahead with our comprehensive IT consulting services.
        </p>
      </div>
    </div>
  );
}

export default ItHero;
