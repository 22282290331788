import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Risk assessment methodologies",
    "Cybersecurity controls",
    "NIST Cybersecurity Framework",
    "ISO 27001",
    "Threat Intelligence sources and Analysis techniques",
    "Security tools such as SIEM",
    "Risk management tools",
    "cloud security principles",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "Ability to meet deadlines",
    "Analytical Skills",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Utilize proficiency in risk assessment methodologies and frameworks such as NIST Cybersecurity Framework and ISO 27001 to assess cyber risks and vulnerabilities.",
    "Conduct comprehensive risk assessments and vulnerability scans to identify potential security gaps and weaknesses in systems and networks.",
    "Apply knowledge of cybersecurity controls and best practices to evaluate the effectiveness of existing security measures and recommend enhancements.",
    "Analyze threat intelligence sources and employ analysis techniques to identify emerging threats and potential security incidents.",
    "Leverage security tools such as SIEM, IDS/IPS, and DLP solutions to monitor security logs and detect anomalous activities.",
    "Utilize risk management tools and platforms to track and manage cyber risks effectively.",
    "Ensure compliance with regulatory requirements such as GDPR, PCI DSS, and HIPAA by implementing appropriate security controls and measures.",
    "Assess cloud security principles and risks associated with cloud environments, developing strategies to mitigate them.",
    "Communicate technical concepts and risk assessment findings to non-technical stakeholders.",
    "Collaborate with internal teams and external stakeholders to develop and implement security policies, procedures, and incident response plans.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Information Security, or related field (or equivalent experience).",
    "Previous experience in cybersecurity risk analysis or related roles with a proven track record.",
    "Certifications such as Certified Information Systems Security Professional (CISSP), Certified Information Security Manager (CISM), or Certified Risk and Information Systems Control (CRISC) are advantageous.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Cybersecurity Risk Analyst"}
          Description={
            "We are seeking a skilled and experienced Cybersecurity Risk Analyst to join our team. As a Cybersecurity Risk Analyst, you will be responsible for evaluating and managing cyber risks, conducting risk assessments, and developing strategies to mitigate potential threats. This role offers an exciting opportunity to work on challenging projects, collaborate with cross-functional teams, and make a meaningful impact in the cybersecurity domain."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
