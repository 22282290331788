import React from "react";
import { useState, useEffect } from "react";
import { Input } from "./../components/index.jsx";
import MessageTxt from "../../major/messagesent/MessageTxt.jsx";
import PhoneInput from "react-phone-number-input";
import { credentials } from "../../../auth.jsx";
import { config } from "../../../auth.jsx";

const SupportPage = () => {
  const [support, setSupport] = useState(false);
  const [business, setBusiness] = useState(true);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkBox, setCheckBox] = useState("");
  const [company, setCompany] = useState("");
  const [queryOption, setQueryOption] = useState("");
  const [learner, setLearner] = useState("");
  const [member, setMember] = useState("");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSections, setModalSections] = useState([]);

  const [lastNameBusiness, setLastNameBusiness] = useState("");
  const [firstNameBusiness, setFirstNameBusiness] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [phoneNumberBusiness, setPhoneNumberBusiness] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [country, setCountry] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [checkBoxBusinessPrivacy, setCheckBoxBusinessPrivacy] = useState(false);
  const [checkBoxService, setCheckBoxService] = useState("");
  const [industry, setIndustry] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [formSubmit2, setFormSubmit2] = useState(false);
  const [messageBusiness, setMessageBusiness] = useState("");

  const handleCheckBoxChange = (e) => {
    setCheckBoxBusinessPrivacy(e.target.checked);
  };

  /*const handleSubmitForm = async (e) => {
    e.preventDefault();

    const formData = {
      query_type: queryOption,
      last_name: lastName,
      first_name: firstName,
      email,
      phone_number: phoneNumber,
      workEmail,
      country,
      jobTitle,
      checkBox,
      company,
      message,
      whta_learner: learner,
    };

    console.log(formData);

    try {
      const response = await fetch(
        `${config.baseURL}/support/support-queries/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("json:", JSON.stringify(formData));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Form submitted successfully");
      //reset fields
      setLastName("");
      setFirstName("");
      setEmail("");
      setPhoneNumber("");
      setWorkEmail("");
      setCountry("");
      setJobTitle("");
      setCheckBox("");
      setCompany("");
      setQueryOption("");
      setLearner("");
      setMessage("");
    } catch (error) {
      console.error("Error:", error);
    }

    if (support) {
      setFormSubmit(!formSubmit);
      console.log("support state", support);
    } else if (business) {
      setFormSubmit2(!formSubmit2);
      console.log("GG");
    }
  };*/
  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((c) => ({
          code: c.cca2, // Alpha-2 code (e.g., "US" for United States)
          name: c.name.common, // Common name (e.g., "United States")
        }));

        // Sort the country list alphabetically by name
        countryList.sort((a, b) => a.name.localeCompare(b.name));

        setCountries(countryList);
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const validate = () => {
    let tempErrors = { ...formErrors };

    tempErrors.queryOption = queryOption ? "" : "Please select a query option.";
    tempErrors.firstName = firstName
      ? firstName.length < 3
        ? "First Name must be at least 3 characters."
        : ""
      : "First Name is required.";

    tempErrors.lastName = lastName
      ? lastName.length < 3
        ? "Last Name must be at least 3 characters."
        : ""
      : "Last Name is required.";

    tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)
      ? ""
      : "Email is not valid.";
    tempErrors.phoneNumber = phoneNumber ? "" : "Phone Number is required.";
    tempErrors.learner = learner ? "" : "Please select an option.";
    tempErrors.member = member ? "" : "Please select an option.";
    tempErrors.message = message
      ? message.length < 50
        ? "Message must be at least 50 characters."
        : ""
      : "Please enter your message.";

    tempErrors.checkBox = checkBox
      ? ""
      : "You must agree to the Privacy Policy.";

    setFormErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const validateForm = () => {
    let tempErrors = { ...formErrors };

    tempErrors.workEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(workEmail)
      ? /@(gmail\.com|yahoo\.com)$/i.test(workEmail)
        ? "Gmail and Yahoo email addresses are not allowed."
        : ""
      : "Work email is not in a valid format.";

    tempErrors.firstNameBusiness = firstNameBusiness
      ? firstNameBusiness.length < 3
        ? "First Name must be at least 3 characters."
        : ""
      : "First Name is required.";

    tempErrors.lastNameBusiness = lastNameBusiness
      ? lastNameBusiness.length < 3
        ? "Last Name must be at least 3 characters."
        : ""
      : "Last Name is required.";

    tempErrors.jobTitle = jobTitle
      ? jobTitle.length < 3
        ? "Job Title must be at least 3 characters."
        : ""
      : "Job Title is required.";

    tempErrors.company = company
      ? company.length < 3
        ? "Company name must be at least 3 characters."
        : ""
      : "Company name is required.";

    tempErrors.country = country ? "" : "Country is required.";
    tempErrors.phoneNumberBusiness = phoneNumberBusiness
      ? ""
      : "Phone Number is required.";
    tempErrors.industry = industry ? "" : "Please select an industry.";
    tempErrors.checkBoxService = checkBoxService
      ? ""
      : "Please select at least one service.";
    tempErrors.messageBusiness = messageBusiness
      ? messageBusiness.length < 50
        ? "Message must be at least 50 characters."
        : ""
      : "Please enter your message.";

    tempErrors.checkBoxBusinessPrivacy = checkBoxBusinessPrivacy
      ? ""
      : "You must agree to the Privacy Policy.";

    setFormErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleCheckboxChange = (value) => {
    if (checkBoxService === value) {
      // If the selected value is already checked, uncheck it
      setCheckBoxService("");
    } else {
      // Otherwise, set the selected value and uncheck others
      setCheckBoxService(value);
    }
  };
  const handleSubmitFormSupport = async (e) => {
    e.preventDefault();

    if (validate()) {
      // Submit the form if all validations pass
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        inquiry_type: queryOption,
        learner_status: learner,
        campus_hub_member_status: member,
        message,
        agreed_to_privacy_policy: true,
      };

      try {
        const response = await fetch(
          `${config.baseURL}/support/support/create/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${credentials}`,
            },
            body: JSON.stringify(formData),
          }
        );
        console.log("Here is your form data", formData);
        let da = JSON.stringify(formData);
        console.log("JSON", da);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Success:", data);

        // Reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setQueryOption("");
        setLearner("");
        setMember("");
        setMessage("");
        console.log("Form submitted successfully");
        setFormSubmit(true);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Highlight all fields with errors
      setTouched({
        queryOption: true,
        firstName: true,
        lastName: true,
        email: true,
        phoneNumber: true,
        learner: true,
        member: true,
        message: true,
        checkBox: true,
      });
    }
  };
  const handleSubmitFormBusiness = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Submit the form if all validations pass
      const formData = {
        email: workEmail,
        first_name: firstNameBusiness,
        last_name: lastNameBusiness,
        job_title: jobTitle,
        company_name: company,
        country,
        phone_number: phoneNumberBusiness,
        industry_type: industry,
        service_needed: checkBoxService,
        agreed_to_privacy_policy: true,
        message: messageBusiness,
      };
      try {
        const response = await fetch(
          `${config.baseURL}/support/business/create/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${credentials}`,
            },
            body: JSON.stringify(formData),
          }
        );
        console.log("json:", JSON.stringify(formData));
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        setFormSubmit2(true);
        // Reset the form fields to their initial state
        setWorkEmail("");
        setFirstNameBusiness("");
        setLastNameBusiness("");
        setJobTitle("");
        setCompany("");
        setCountry("");
        setPhoneNumberBusiness("");
        setIndustry("");
        setCheckBoxService([]);
        setMessageBusiness("");
        console.log("Form submitted successfully");
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Highlight all fields with errors
      setTouched({
        workEmail: true,
        firstNameBusiness: true,
        lastNameBusiness: true,
        jobTitle: true,
        company: true,
        country: true,
        phoneNumberBusiness: true,
        industry: true,
        checkBoxService: true,
        checkBoxBusinessPrivacy: true,
      });
    }
  };

  const handleSupport = () => {
    setSupport(true);
    setBusiness(false);
  };

  const handleBusiness = () => {
    setBusiness(true);
    setSupport(false);
  };
  const handleShowModal = (title, sections) => {
    setModalTitle(title);
    setModalSections(sections);
    setShowModal(true);
  };
  const privacySections = [
    {
      subtitle: "Introduction",
      content:
        "wHTa Networks is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By using our website or services, you agree to the terms of this Privacy Policy.",
    },
    {
      subtitle: "Information We Collect",
      content: `
        2.1 Personal Information
        We may collect personal information that you provide to us directly, including but not limited to:
        • Contact Information: Name, email address, phone number, and mailing address.
        • Account Information: Username, password, and other registration details.
        • Payment Information: Credit/debit card details, PayPal account information, and M-Pesa account information.
        • Educational Information: Course enrollments, assignment submissions, assessment results, and other academic records.
        2.2 Non-Personal Information
        We may collect non-personal information about your use of our website and services, including:
        • Device Information: IP address, browser type, operating system, and device identifiers.
        • Usage Information: Pages visited, time spent on pages, links clicked, and other usage data.
        • Cookies and Tracking Technologies: We use cookies and similar technologies to track your activity on our website and improve our services.
      `,
    },
    {
      subtitle: "How We Use Your Information",
      content: `
        3.1 Personal Information
        We use your personal information to:
        • Provide and Manage Services: Enroll you in courses, track your progress, and issue certificates.
        • Communicate with You: Send updates, newsletters, promotional materials, and respond to your inquiries.
        • Process Payments: Handle transactions and manage billing.
        • Improve Our Services: Analyze usage data and gather feedback to enhance our offerings.
        3.2 Non-Personal Information
        We use non-personal information to:
        • Analyze Trends: Understand how our website is used and improve user experience.
        • Conduct Research: Gather insights to optimize our services and marketing strategies.
        • Ensure Security: Monitor and protect against fraudulent activity and security threats.
      `,
    },
    {
      subtitle: "Information Sharing and Disclosure",
      content: `
        4.1 Third-Party Service Providers
        We may share your information with third-party service providers who perform services on our behalf, such as payment processors, email service providers, and analytics providers.
        4.2 Legal Requirements
        We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a subpoena or court order).
        4.3 Business Transfers
        In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the business assets.
      `,
    },
    {
      subtitle: "Your Choices and Rights",
      content: `
        5.1 Access and Correction
        You may access and update your personal information by logging into your account or contacting us directly.
        5.2 Opt-Out
        You may opt out of receiving promotional communications from us by following the unsubscribe instructions included in each email.
        5.3 Data Retention
        We retain your information only for as long as necessary to provide our services, comply with legal obligations, and resolve disputes.
      `,
    },
    {
      subtitle: "Security",
      content: `
        6.1 Security Measures
        We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
        6.2 Data Breaches
        In the event of a data breach, we will notify you in accordance with applicable laws and take appropriate steps to mitigate the impact.
      `,
    },
    {
      subtitle: "Changes to This Policy",
      content: `
        7.1 Updates
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website and updating the effective date.
        7.2 Your Responsibility
        It is your responsibility to review this Privacy Policy periodically for any changes. Your continued use of our website or services after any changes constitutes your acceptance of the updated policy.
      `,
    },
  ];

  return (
    <div className="w-full bg-[#F9E1EC]">
      <div className="w-10/12 mx-auto py-10">
        <h1 className="text-purple-900 flex justify-center lg:text-[3rem] md:text-[2rem] sm:text-[2rem] font-bold mt-10">
          Complete Only One Form
        </h1>
        {support && (
          <div className="mt-10">
            <div className="flex flex-row justify-center lg:gap-16 md:gap-10 sm:gap-4 mt-10 font-semibold">
              <button
                className="bg-purple-darker text-white py-2 rounded-lg min-w-48 lg:text-xl md:text-lg "
                onClick={handleSupport}
              >
                Support
              </button>
              <button
                className="bg-white py-2 rounded-lg min-w-48 lg:text-xl md:text-lg"
                onClick={handleBusiness}
              >
                Business Sales
              </button>
            </div>

            <div className="flex lg:ml-auto mt-10">
              <div className="flex flex-col lg:flex-row justify-between gap-6">
                <div className="lg:w-1/2 md:px-2 lg:px-32 md:flex md:flex-col">
                  <h2 className="md:flex justify-center font-bold my-4 lg:text-[2rem] md:text-[2rem]">
                    Service and Support
                  </h2>
                  <p>
                    {`Before you complete this form, ensure that you're either currently enrolled in wHTa e-learning/wHTa Campus Hub or looking forward to register. Otherwise, complete the Business Sales form`}
                  </p>
                </div>

                <div className="lg:w-1/2 bg-white rounded shadow-sm md:mt-10 sm:mt-10">
                  <form onSubmit={handleSubmitFormSupport}>
                    <div className="w-10/12 mx-auto my-10 flex flex-col gap-4">
                      <div>
                        {touched.queryOption && formErrors.queryOption && (
                          <div className="text-red-500">
                            {formErrors.queryOption}
                          </div>
                        )}
                        <select
                          id="queryOption"
                          className="w-full h-16 md:h-12 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                          value={queryOption}
                          onChange={(e) => setQueryOption(e.target.value)}
                        >
                          <option value="">My query is related to:*</option>
                          <option value="ELEARNING_ENROLLMENT">
                            wHTa e-learning Enrollment
                          </option>
                          <option value="ELEARNING_SUSPENSION">
                            wHTa e-learning Account Suspension
                          </option>
                          <option value="CAMPUS_HUB_REGISTRATION">
                            wHTa Campus Hub Registration
                          </option>
                          <option value="CAMPUS_HUB_GENERAL">
                            wHTa Campus Hub General Inquiry
                          </option>
                        </select>
                      </div>

                      <div className="flex gap-3 justify-between">
                        <div>
                          {touched.firstName && formErrors.firstName && (
                            <div className="text-red-500">
                              {formErrors.firstName}
                            </div>
                          )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="First Name*"
                            type="shortText"
                            value={firstName}
                            onChange={setFirstName}
                          />
                        </div>
                        <div>
                          {touched.lastName && formErrors.lastName && (
                            <div className="text-red-500">
                              {formErrors.lastName}
                            </div>
                          )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="Last Name*"
                            type="shortText"
                            value={lastName}
                            onChange={setLastName}
                          />
                        </div>
                      </div>
                      <div>
                        {touched.email && formErrors.email && (
                          <div className="text-red-500">{formErrors.email}</div>
                        )}
                        <Input
                          isRequired="true"
                          inputClass="1"
                          placeholder="Email Address*"
                          type="email"
                          value={email}
                          onChange={setEmail}
                        />
                      </div>
                      <div>
                        {touched.phoneNumber && formErrors.phoneNumber && (
                          <div className="text-red-500">
                            {formErrors.phoneNumber}
                          </div>
                        )}
                        <PhoneInput
                          international
                          defaultCountry="KE"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          className="border py-3 px-4 w-full rounded focus:outline-none "
                          style={{ backgroundColor: "#FFF5FE" }}
                          required
                        />
                      </div>
                      <div>
                        {touched.learner && formErrors.learner && (
                          <div className="text-red-500">
                            {formErrors.learner}
                          </div>
                        )}
                        <select
                          id="learner"
                          className="w-full h-16 md:h-12 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                          value={learner}
                          onChange={(e) => setLearner(e.target.value)}
                        >
                          <option value="">
                            Are you a wHTA Academy learner?*
                          </option>
                          <option value="YES">Yes</option>
                          <option value="WANT_TO_ENROLL">
                            I would like to enroll
                          </option>
                          <option value="NO">No</option>
                        </select>
                      </div>
                      <div>
                        {touched.member && formErrors.member && (
                          <div className="text-red-500">
                            {formErrors.member}
                          </div>
                        )}
                        <select
                          id="member"
                          className="w-full h-16 md:h-12 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                          value={member}
                          onChange={(e) => setMember(e.target.value)}
                        >
                          <option value="">Are you a wHTA Campus Hub?*</option>
                          <option value="yes">Yes</option>
                          <option value="enroll">I would like to enroll</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      {touched.message && formErrors.message && (
                        <div className="text-red-500">{formErrors.message}</div>
                      )}
                      <textarea
                        placeholder="Type your message here*"
                        className="w-full h-32 md:h-32 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      ></textarea>
                      {touched.checkBox && formErrors.checkBox && (
                        <div className="text-red-500">
                          {formErrors.checkBox}
                        </div>
                      )}
                      <div className="flex items-start justify-start">
                        <input
                          inputClass="4"
                          type="checkbox"
                          checked={checkBox === "Privacy"}
                          onChange={(e) =>
                            setCheckBox(e.target.checked ? "Privacy" : "")
                          }
                          className="mt-1"
                          value="Privacy"
                        />
                        <p className="ml-1">
                          By submitting this form, I agree to the processing of
                          my personal data by wHTa Networks as described in the
                          <a
                            onClick={() => {
                              handleShowModal(
                                "Privacy Policy",
                                privacySections
                              );
                            }}
                            className="text-[#E917BB] font-semibold ml-1 hover:cursor-pointer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="border py-2 bg-purple-darker text-white font-bold w-4/12 m-auto rounded-lg mt-7"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  {formSubmit ? (
                    <div className="items-center justify-center flex">
                      <MessageTxt formState={formSubmit} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
        {business && (
          <div className="mt-10">
            <div className="flex flex-row justify-center lg:gap-16 md:gap-10 sm:gap-4 mt-10 font-semibold">
              <button
                className="bg-white py-2 rounded-lg min-w-48 lg:text-xl md:text-lg "
                onClick={handleSupport}
              >
                Support
              </button>
              <button
                className="bg-purple-darker text-white py-2 rounded-lg min-w-48 lg:text-xl md:text-lg"
                onClick={handleBusiness}
              >
                Business Sales
              </button>
            </div>

            <div className="flex lg:ml-auto mt-10">
              <div className="flex flex-col lg:flex-row justify-between gap-6">
                <div className="lg:w-1/2 md:px-2 lg:px-32 md:flex md:flex-col">
                  <h2 className="md:flex justify-center font-bold my-4 lg:text-[2rem] md:text-[2rem]">
                    Contact Our Business Sales Team
                  </h2>
                  <p>
                    {`Our technical team shall customize a solution that meets your organization needs.`}
                  </p>
                </div>

                <div className="lg:w-1/2 bg-white rounded shadow-sm md:mt-10 sm:mt-10">
                  <form onSubmit={handleSubmitFormBusiness}>
                    <div className="w-10/12 mx-auto my-10 flex flex-col gap-4">
                      <div>
                        {touched.workEmail && formErrors.workEmail && (
                          <div className="text-red-500">
                            {formErrors.workEmail}
                          </div>
                        )}
                        <Input
                          isRequired="true"
                          inputClass="1"
                          placeholder="Work Email*"
                          type="email"
                          value={workEmail}
                          onChange={setWorkEmail}
                        />
                      </div>

                      <div className="flex gap-3 justify-between">
                        <div>
                          {touched.firstNameBusiness &&
                            formErrors.firstNameBusiness && (
                              <div className="text-red-500">
                                {formErrors.firstNameBusiness}
                              </div>
                            )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="First Name*"
                            type="shortText"
                            value={firstNameBusiness}
                            onChange={setFirstNameBusiness}
                          />
                        </div>
                        <div>
                          {touched.lastNameBusiness &&
                            formErrors.lastNameBusiness && (
                              <div className="text-red-500">
                                {formErrors.lastNameBusiness}
                              </div>
                            )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="Last Name*"
                            type="shortText"
                            value={lastNameBusiness}
                            onChange={setLastNameBusiness}
                          />
                        </div>
                      </div>
                      <div className="flex gap-3 justify-between">
                        <div>
                          {touched.jobTitle && formErrors.jobTitle && (
                            <div className="text-red-500">
                              {formErrors.jobTitle}
                            </div>
                          )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="Job Title*"
                            type="shortText"
                            value={jobTitle}
                            onChange={setJobTitle}
                          />
                        </div>
                        <div>
                          {touched.company && formErrors.company && (
                            <div className="text-red-500">
                              {formErrors.company}
                            </div>
                          )}
                          <Input
                            isRequired="true"
                            inputClass="1"
                            placeholder="Company*"
                            type="shortText"
                            value={company}
                            onChange={setCompany}
                          />
                        </div>
                      </div>
                      <div className="flex gap-3 justify-between">
                        <div>
                          {touched.country && formErrors.country && (
                            <div className="text-red-500">
                              {formErrors.country}
                            </div>
                          )}
                          <select
                            id="country"
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            className="border py-3 px-4 w-full rounded focus:outline-none"
                            style={{ backgroundColor: "#FFF5FE" }}
                            required
                          >
                            <option value="" disabled>
                              Select a country*
                            </option>
                            {countries.map((c) => (
                              <option key={c.code} value={c.code}>
                                {c.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          {touched.phoneNumberBusiness &&
                            formErrors.phoneNumberBusiness && (
                              <div className="text-red-500">
                                {formErrors.phoneNumberBusiness}
                              </div>
                            )}
                          <PhoneInput
                            international
                            defaultCountry="KE"
                            value={phoneNumber}
                            onChange={setPhoneNumberBusiness}
                            className="border py-3 px-4 w-full rounded focus:outline-none"
                            style={{ backgroundColor: "#FFF5FE" }}
                            required
                          />
                        </div>
                      </div>
                      <div>
                        {touched.industry && formErrors.industry && (
                          <div className="text-red-500">
                            {formErrors.industry}
                          </div>
                        )}
                        <select
                          id="industry"
                          className="w-full h-16 md:h-12 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                          value={industry}
                          onChange={(e) => setIndustry(e.target.value)}
                        >
                          <option value="">
                            Select the type of industry where you operate*
                          </option>
                          <option value="AGRICULTURE">
                            Agriculture, Forestry, and Fishing
                          </option>
                          <option value="MINING">
                            Mining, Quarrying, and Oil and Gas Extraction
                          </option>
                          <option value="UTILITIES">Utilities</option>
                          <option value="CONSTRUCTION">Construction</option>
                          <option value="MANUFACTURING">Manufacturing</option>
                          <option value="WHOLESALE_TRADE">
                            Wholesale Trade
                          </option>
                          <option value="RETAIL_TRADE">Retail Trade</option>
                          <option value="TRANSPORTATION">
                            Transportation and Warehousing
                          </option>
                          <option value="INFORMATION">Information</option>
                          <option value="FINANCE">Finance and Insurance</option>
                          <option value="REAL_ESTATE">
                            Real Estate and Rental and Leasing
                          </option>
                          <option value="PROFESSIONAL_SERVICES">
                            Professional, Scientific, and Technical Services
                          </option>
                          <option value="MANAGEMENT">
                            Management of Companies and Enterprises
                          </option>
                          <option value="ADMINISTRATIVE">
                            Administrative and Support and Waste Management and
                            Remediation Services
                          </option>
                          <option value="EDUCATION">
                            Educational Services
                          </option>
                          <option value="HEALTHCARE">
                            Health Care and Social Assistance
                          </option>
                          <option value="ARTS">
                            Arts, Entertainment, and Recreation
                          </option>
                          <option value="ACCOMMODATION">
                            Accomodation and Food Services
                          </option>
                        </select>
                      </div>

                      <div>
                        <p className="flex text-purple-900 font-semibold">
                          Check one box with one Service your organization
                          needs.<p className="text-red-600 font-semibold">*</p>
                        </p>
                        <div className="flex flex-col gap-3 mt-5">
                          {touched.checkBoxService &&
                            formErrors.checkBoxService && (
                              <div className="text-red-500">
                                {formErrors.checkBoxService}
                              </div>
                            )}
                          {[
                            {
                              label: "Web-based Application",
                              value: "Web-based_Application",
                            },
                            {
                              label: "Mobile Application",
                              value: "Mobile_Application",
                            },
                            {
                              label: "E-Commerce Application",
                              value: "E-Commerce_Application",
                            },
                            {
                              label: "Business/Company/Organization Website",
                              value: "Business/Company/Organization_Website",
                            },
                            {
                              label: "Product Design and UI/UX Research",
                              value: "Product_Design_and_UX/UI_Research",
                            },
                            {
                              label: "IT & Cybersecurity Consulting",
                              value: "IT&Cybersecurity_Consulting",
                            },
                            {
                              label: "Not Sure/Need Help Deciding",
                              value: "Not_sure/Need_help",
                            },
                          ].map((option) => (
                            <label
                              key={option.value}
                              className="flex items-center space-x-3"
                            >
                              <input
                                type="checkbox"
                                checked={checkBoxService === option.value}
                                onChange={() =>
                                  handleCheckboxChange(option.value)
                                }
                                value={option.value}
                                className="form-checkbox h-6 w-6"
                              />
                              <span>{option.label}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                      <div>
                        {formErrors.messageBusiness && (
                          <div className="text-red-500">
                            {formErrors.messageBusiness}
                          </div>
                        )}
                        <textarea
                          placeholder="Briefly describe the requirements of the service you selected above*"
                          className="w-full h-32 md:h-32 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2"
                          value={messageBusiness}
                          onChange={(e) => {
                            setMessageBusiness(e.target.value);
                          }}
                        ></textarea>
                      </div>

                      {formErrors.checkBoxBusinessPrivacy && (
                        <div className="text-red-500">
                          {formErrors.checkBoxBusinessPrivacy}
                        </div>
                      )}
                      <div className="flex items-start">
                        <input
                          type="checkbox"
                          checked={checkBoxBusinessPrivacy === "Privacy"}
                          onChange={(e) =>
                            setCheckBoxBusinessPrivacy(
                              e.target.checked ? "Privacy" : ""
                            )
                          }
                          className="mt-1"
                          value="Privacy"
                        />
                        <p className="ml-1">
                          By submitting this form, I agree to the processing of
                          my personal data by wHTa Networks as described in the
                          <a
                            onClick={() => {
                              handleShowModal(
                                "Privacy Policy",
                                privacySections
                              );
                            }}
                            className="text-[#E917BB] font-semibold ml-1 hover:cursor-pointer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="w-4/12 m-auto border py-2 bg-purple-darker text-white font-bold rounded-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  {formSubmit2 ? (
                    <div className="items-center justify-center flex">
                      <MessageTxt formState={formSubmit2} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Modal */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity ${
          showModal ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      ></div>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 transition-transform ${
          showModal ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4 md:mx-0">
          <div className="flex justify-between items-center border-b p-4">
            <h2 className="text-xl font-bold text-purple-600">{modalTitle}</h2>
            <button
              onClick={() => setShowModal(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="p-4 max-h-[70vh] overflow-y-auto">
            {modalSections.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg font-semibold text-purple-300">
                  {section.subtitle}
                </h3>
                <p className="mt-2 whitespace-pre-line">{section.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
