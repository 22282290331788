import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.jsx";

import "./index.css";
import { store } from "./components/wHTA_Academy/stores/mainStore.js";
//console.log('Store:', store);
// ReactDOM.createRoot(document.getElementById('root')).render(
//   <React.StrictMode store={store}>
//     <Provider >
//       <App />
//     </Provider>
//   </React.StrictMode>,
// )

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);
