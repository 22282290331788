import React from "react";
import { Whyus } from "../../../major/index";


const Whycus = () => {
  return (
    <div className="bg-purple-f flex items-center justify-center px-4 sm:px-20">
      <Whyus/>
    </div>
  );
};

export default Whycus;