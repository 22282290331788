import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";
function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "IDS/IPS",
    "Firewalls",
    "VPNs",
    "Wireshark",
    "Vulnerability assessment",
    "Penetration Testing",
    "Security information and event management (SIEM) systems",
    "Encryption protocols",
    "Cryptographic algorithms",
    "Antivirus software",
    "Network segmentation",
    "PCI",
    "DSS",
    "HIPAA",
    "GDPR",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Organization",
    "Adaptability",
    "Creativity",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Implementing infrastructure automation using tools such as Terraform or Ansible, enabling consistent and efficient provisioning of resources.",
    "Managing containerization and orchestration platforms like Docker and Kubernetes, optimizing resource utilization and scalability.",
    "Designing and maintaining continuous integration and deployment (CI/CD) pipelines using tools like Jenkins, GitLab CI, or CircleCI, automating the software delivery process.",
    "Managing cloud platforms such as AWS, Azure, or Google Cloud Platform, leveraging cloud-native services to enhance system capabilities.",
    "Implementing monitoring and logging solutions using tools like Prometheus, ELK stack, or Splunk, ensuring timely detection and resolution of issues.",
    "Utilizing scripting languages such as Bash, Python, or PowerShell to automate routine tasks and streamline operations.",
    "Implementing networking concepts and security best practices for cloud environments, ensuring data protection and compliance.",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science, Information Technology, or related field (or equivalent experience).",
    "Previous experience in network security or related roles with a demonstrated track record of securing networks.",
    "Certifications such as Certified Information Systems Security Professional (CISSP), Certified Ethical Hacker (CEH), or Cisco Certified Network Associate (CCNA) Security are advantageous.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Network Security Analyst"}
          Description={
            "We are seeking a highly skilled and motivated Network Security Analyst to join our team. The primary responsibility of this role is to ensure the security and integrity of our network infrastructure by implementing robust security measures and protocols. The ideal candidate will have a strong background in network security technologies, along with the ability to analyze and respond to security threats effectively."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
