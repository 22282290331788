import React from 'react'
import { WhyChooseUs } from "../../../micros/index";

function WhyPentesting() {
    const whyusData = [
        {
            title: "Expertise and Experience",
            description:"Our certified ethical hackers conduct penetration tests across industries.",
            icon: "icons/brain.svg",
          },  
        {
            title: "Comprehensive Approach",
            description:"We check your systems, apps, and networks for problems and fix the important ones first.",
            icon: "icons/softdev.svg",
          },
        {
            title: "Customized Solutions", 
            description: "We tailor our penetration testing services to meet your unique security needs and objectives.",
            icon: "icons/headset1.svg",
          },
        {
            title: "Actionable Insights",
            description:"We give detailed reports and suggestions to make your defenses stronger and reduce risks.",
            icon: "icons/money.svg",
        },
        {
        title: "Confidentiality and Integrity",
        description:"We keep your sensitive information safe and private during testing with strict confidentiality standards.",
        icon: "icons/money.svg",
        },
      ];
    
      return (
        
        <div className="w-full bg-purple-f">
          <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
            Why choose us
          </h2>
          <div className="flex flex-wrap items-center justify-center  gap-4 pb-[4rem] sm:pb-[6rem] lg:pb-[12rem]">
            {whyusData.map((whyus, index) => (
              <WhyChooseUs {...whyus} key={index} />
            ))}
          </div>
        </div>
      );
}

export default WhyPentesting