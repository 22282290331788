import React, { useState, useRef } from "react";
import { Input, SingleClick, TextArea } from "../../../micros";

function StepThree({ onNext, closePopup, formData, updateFormData }) {
  // destructure the formData object for answers
  const {
    pitch_description,
    unique_elements,
    target_audience,
    potential_obstacles,
    immediate_actions,
  } = formData;
  const [errors, setErrors] = useState([]);
  const [wordCounts, setWordCounts] = useState({
    pitch_description: 0,
    unique_elements: 0,
    target_audience: 0,
    potential_obstacles: 0,
    immediate_actions: 0,
  });

  const wordCountRanges = {
    pitch_description: [300, 500],
    unique_elements: [200, 300],
    target_audience: [200, 300],
    potential_obstacles: [200, 300],
    immediate_actions: [80, 150],
  };
  const textAreaRefs = {
    pitch_description: useRef(null),
    unique_elements: useRef(null),
    target_audience: useRef(null),
    potential_obstacles: useRef(null),
    immediate_actions: useRef(null),
  };
  // regex to track word count
  // let regex = /(\S+\b)/g;
  // regex.test(sentence) ? sentence.match(regex).length : 0;

  const handleAnswerChange = (field, value) => {
    updateFormData({ [field]: value });

    // Calculate word count
    const words = value.trim().split(/\s+/).filter(Boolean);
    const wordCount = words.length;
    console.log(wordCount);
    setWordCounts((prev) => ({
      ...prev,
      [field]: wordCount,
    }));
  };
  const saveData = () => {
    let newErrors = [];
    let scrollToError = null;
    // if (
    //   pitch_description == "" ||
    //   unique_elements == "" ||
    //   target_audience == "" ||
    //   potential_obstacles == "" ||
    //   immediate_actions == ""
    // ) {
    //   newErrors.push("Please answer all questions");
    // }

    Object.keys(wordCountRanges).forEach((field) => {
      const [min, max] = wordCountRanges[field];
      const wordCount = wordCounts[field];
      let question;
      switch (field) {
        case "pitch_description":
          question = "a";
          break;
        case "unique_elements":
          question = "b";
          break;
        case "target_audience":
          question = "c";
          break;
        case "potential_obstacles":
          question = "d";
          break;
        case "immediate_actions":
          question = "e";
          break;
        default:
          break;
      }

      if (!formData[field]) {
        newErrors.push(`Please answer the question: ${question}`);
        if (!scrollToError) scrollToError = textAreaRefs[field];
      } else if (wordCount < min || wordCount > max) {
        newErrors.push(
          `Words must be between ${min} - ${max} for question ${question}`
        );
        if (!scrollToError) scrollToError = textAreaRefs[field];
      }
    });

    if (newErrors.length > 0) {
      setErrors(newErrors);
      if (scrollToError) {
        scrollToError.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    setErrors([]);
    onNext();
  };

  const renderWordCountMessage = (field, wordCount) => {
    const [min, max] = wordCountRanges[field];
    const isWithinRange = wordCount >= min && wordCount <= max;

    return (
      <p
        className={`absolute right-[] bottom-2 flex justify-end pr-[2%] pt-[0.3%] ml-[2%] text-[8pt] w-[96%] text-[#05195F] bg-purple-f`}
        // {`/* ${!isWithinRange ? "text-gray-900" : "text-gray-600"}*/ `}
      >
        Words must be between {min} - {max} ({wordCount})
      </p>
    );
  };

  return (
    <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 sm:mx-8 w-[90vw] sm:w-auto sm:h-[] md:w-[] min-h-[90vh] md:max-w-[50rem] lg:w-[]  xl:w-[] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto">
      <p
        className="absolute flex justify-center items-center cursor-pointer  right-2 top-2 rounded-full text-center z-100"
        onClick={closePopup}
      >
        {/* <img src="/cancel.svg" alt="" className="size-4" /> */}
        <img src="/close.svg" alt="" className="size-4" />
      </p>
      <div className="font-poppins pb-6">
        <div className="px-6 pt-10">
          <h2 className="text-2xl text-gray-800 font-poppins-semi-bold mb-3">
            3. Step three
          </h2>
          {/* Registerartion form */}
          <form action="" className="">
            <h2 className="text-xl font-poppins-semi-bold text-[#05195F] mb-2">
              Submit your pitch
            </h2>
            <label htmlFor="questions" className="">
              Complete the form below and share your pitch. Tell us about your
              unique idea, how it can impact others, and what you need to make
              it happen. This is your chance to become part of a vibrant
              community of thinkers and doers who are making a difference. Let's
              make great things happen together!
            </label>
            <div>
              <p className="my-2 text-xs">
                a. Briefly describe your idea and what inspired it. What problem
                does it solve or what opportunity does it create?
              </p>
              <div className="relative">
                <TextArea
                  rows="10"
                  // cols=""
                  textareaClass="3"
                  value={formData.pitch_description || ""}
                  onChange={(value) =>
                    handleAnswerChange("pitch_description", value)
                  }
                  ref={textAreaRefs.pitch_description}
                />
                {renderWordCountMessage(
                  "pitch_description",
                  wordCounts.pitch_description
                )}
              </div>
              <p className="my-2 text-xs">
                b. Explain what sets your idea apart from others. What creative
                elements or unique approaches are you incorporating?
              </p>
              <div className="relative">
                <TextArea
                  rows="8"
                  // cols=""
                  textareaClass="3"
                  value={formData.unique_elements || ""}
                  onChange={(value) =>
                    handleAnswerChange("unique_elements", value)
                  }
                  ref={textAreaRefs.unique_elements}
                />
                {renderWordCountMessage(
                  "unique_elements",
                  wordCounts.unique_elements
                )}
              </div>
              <p className="my-2 text-xs">
                c. Identify the target audience or community for your idea. How
                will you connect with them and make an impact?
              </p>
              <div className="relative">
                <TextArea
                  rows="8"
                  // cols=""
                  textareaClass="3"
                  value={formData.target_audience || ""}
                  onChange={(value) =>
                    handleAnswerChange("target_audience", value)
                  }
                  ref={textAreaRefs.target_audience}
                />
                {renderWordCountMessage(
                  "target_audience",
                  wordCounts.target_audience
                )}
              </div>
              <p className="my-2 text-xs">
                d. Anticipate potential obstacles and describe your strategies
                for overcoming them.
              </p>
              <div className="relative">
                <TextArea
                  rows="8"
                  // cols=""
                  textareaClass="3"
                  value={formData.potential_obstacles || ""}
                  onChange={(value) =>
                    handleAnswerChange("potential_obstacles", value)
                  }
                  ref={textAreaRefs.potential_obstacles}
                />
                {renderWordCountMessage(
                  "potential_obstacles",
                  wordCounts.potential_obstacles
                )}
              </div>
              <p className="my-2 text-xs">
                e. What immediate actions will you take if accepted into the
                wHTa Campus Hub?
              </p>
              <div className="relative">
                <TextArea
                  rows="6"
                  // cols=""
                  textareaClass="3"
                  value={formData.immediate_actions || ""}
                  onChange={(value) =>
                    handleAnswerChange("immediate_actions", value)
                  }
                  ref={textAreaRefs.immediate_actions}
                />
                {renderWordCountMessage(
                  "immediate_actions",
                  wordCounts.immediate_actions
                )}
              </div>
            </div>
            {errors.length > 0 && (
              <div className="text-red-900 text-xs mb-4">
                <ul>
                  {errors.map((error, index) => (
                    <li key={index} className="mb-1 block">
                      {error}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-4 w-full flex justify-center">
              <SingleClick
                buttonClass="10"
                buttonText="Continue"
                buttonType="button"
                onClick={saveData}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StepThree;
