import React, { useState } from "react";
import { SingleClick, WhatWeDoCard } from "../../../micros/index";
import { Diversity2 } from "@mui/icons-material";

function SoftwareService() {
  const cardData = [
    {
      icon: "icons/custom.svg",
      title: "Custom Application",
      description:
        "Workflow Management System :E-learning platforms :Point of Sales :Hospital Management System :School Management System",
    },
    {
      icon: "icons/brain.svg",
      title: "AI Chat-bots",
      description:
        "Chatbots Development:Chatbot Training:Chatbot Integration with existingwebsite or applications",
    },
    {
      icon: "icons/web.svg",
      title: "Software Modernization",
      description:
        "Platform Migration:Cloud Enabling:Marketplace:Microservices",
    },
    {
      icon: "icons/cyber.svg",
      title: "Cyber Resilience",
      description:
        "Threat Hunting, and Reporting:Cybersecurity Risk Assessment:Incident and Response Team:Business Continuity Plan",
    },
  ];

  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-center bg-purple-f p-[2rem] pt-[80px] pl-[50px] sm:pb-[6rem] mx-0 w-full min-h-screen">
        <div className="flex   flex-col gap-2 sm:gap-6 lg:gap-4 w-full sm:w-4/7 lg:w-2/5 text-left">
          <div className="flex flex-col">
            <h3 className="text-purple-normal-active text-[40px] font-quicksand-bold">
              <span className="text-purple-dark block">Let us transform</span>
              <span className="text-purple-dark block mt-[-1.0rem]">
                your business!
              </span>
            </h3>
          </div>
          <div className="flex flex-col">
            <span className="text-black-a font-poppins">
              Our Team is Dedicated to Providing Quality Services
            </span>
            <span className="text-black-a font-poppins">
              that meet your Business needs.
            </span>
          </div>
          <div>
            {/*
            <SingleClick
              buttonClass="20"
              buttonText={"Download Catalogue"}
              buttonType="button"
              fileUrl="/wHTa Networks_catalogue.pdf"
              // onClick={() => handleBrochureDownload(course.brochureUrl)}
            />*/}
          </div>
        </div>

        <div className="flex-1 flex flex-wrap gap-3 justify-center items-stretch lg:w-3/5">
          {cardData.map((card, index) => (
            <WhatWeDoCard
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default SoftwareService;
