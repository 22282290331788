import React, { useState } from "react";
import { HalfHeroSection, SingleClick } from "../../../micros/index";
import Modal from "../../../micros/GetQuoteForm/GetQuoteForm";
import CheckboxesCard from "../../../micros/BookmeetingForm/hero/CheckBooker";
function ServicesHerosection({
  serviceTitleImg,
  serviceTitle,
  serviceDescription1,
  serviceDescription2,
  serviceImg,
}) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const scrollToItem = () => {
    const item = document.getElementById("learn_more");
    if (item) {
      item.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    //Content for the first section

    <div className="flex flex-col h-auto  w-full p-auto overflow-y-hidden">
      <HalfHeroSection backgroundImage={serviceTitleImg} text={serviceTitle} />

      {/* Content for the second section  */}
      <div className=" bg-blue-50 flex justify-center">
        <div
          className="second-section flex-1  flex flex-col md:flex-row max-w-8xl py-8
        items-center justify-between font-poppins-semi-bold sm:text-sm lg:text-[1.11rem] h-auto "
        >
          <div className="w-5/6 sm:w-3/4 md:w-1/2 mb-4 lg:mb-8 py-4 pl-0 md:pl-20">
            <p className="mb-4 w-full text-lg">{serviceDescription1}</p>
            <p className="mb-4 text-lg">{serviceDescription2}</p>
            <div className="pt-5 ">
                 
                <SingleClick
                    buttonClass="9"
                    buttonText="Learn More"
                    buttonType="button"
                    onClick={scrollToItem}
                />
            </div>
          </div>

          {/*Second Image*/}
          <div className="w-5/6 sm:w-3/4  xl:pb-14">
            <img
              src={serviceImg}
              alt="Whta Icon"
              className="w-full lg:w-auto h-auto   sm:p-5 lg:px-28 "
            />
          </div>
        </div>
      </div>
      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
          <div className="bg-white mt-32 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[42rem]  rounded-lg max-h-[80vh]    overflow-y-auto">
            {/* Add your popup content here */}
            <CheckboxesCard onClose={closePopup} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ServicesHerosection;
