import React from "react";
import { CampusCard } from "../../../micros/index";

function Readytojoin() {
  const details = [
    {
      title: "Emerging Technologiess",
      image: "/campusjoin1.svg",
      description:
        "Explore the future with hands-on experience in blockchain technology, AI chatbot development, and machine learning. Master cutting-edge innovations shaping tomorrow's word.",
    },
    {
      title: "Software Engineering",
      image: "/campusjoin2.svg",
      description:
        "Turn ideas into reality by developing robust web and mobile applications. Gain practical experience in platform development and collaborative software projects",
    },
    {
      title: "Cybersecurity",
      image: "/campusjoin3.svg",
      description:
        "Equip yourself with essential skills in Cybersecurity. Learn penetration testing and cyber defense to protect digital landscape against threats",
    },
  ];

  return (
    <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8 ">
      <hr className="w-full h-[2.5px] bg-gray-400" />
      {/* <hr className="w-full h-[2.5px] bg-gray-400 hidden" /> */}
      <div className="flex flex-col items-center sm:justify-center sm:gap-4 mt-4">
        <h1 className="text-[#9B1F96] text-2xl sm:text-4xl font-poppins-semi-bold">
          Ready to Join Us
        </h1>
        <p className="text-gray-600 px-8 text-center text-lg font-poppins">
          Join wHTa Networks Campus Hub and Develop the future of technology
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 md:flex-row sm:justify-between md:px-20 py-4 px-4">
        {details.map((detail, index) => (
          <CampusCard
            key={index}
            title={detail.title}
            image={detail.image}
            description={detail.description}
          />
        ))}
      </div>
      <hr className="w-full h-[2.5px] bg-gray-400" />
    </div>
  );
}

export default Readytojoin;
