import React from "react";
import JoinusSection from "../../components/sections/wHTaCampus/JoinusSection";
import HeroSection from "../../components/sections/wHTaCampus/HeroSection";

function Campus() {
  return (
    <div className="pt-24">
      <HeroSection />
      <JoinusSection />
    </div>
  );
}

export default Campus;
