/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Service from "./Images";

const PortfolioOne = () => {
  const servicesData = [
    {
      icon: "icons/portfolio-3.PNG",
    },
    {
      icon: "icons/portfolio-2.PNG",
    },
    {
      icon: "icons/portfolio-1.PNG",
    },
    {
      icon: "icons/portfolio-4.PNG",
    },
    {
      icon: "icons/portfolio-5.PNG",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1, //Adjusted to show only one slide
        },
      },
    ],
  };

  return (
    <div className="w-full max-w-8xl px-1">
      <div className="bg-element2 mt-0  mb-20 bg-cover bg-no-repeat bg-center">
        <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
          Samples of Our Work
        </h2>
      </div>
      <div className="slider-container  mx-1">
        <Slider {...settings}>
          {servicesData.map((service, index) => (
            <div key={index} className="service-container pl-2 sm:pl-8">
              <Service {...service} />
            </div>
          ))}
        </Slider>
        <style>{`
          .slick-prev,
          .slick-next {
            z-index: 10;
            height: 28rem;
            width: 6rem;
          }

          .slick-prev { 
            background: linear-gradient(to right, rgb(255, 217, 251), transparent);
          }

          .slick-next {
            background: linear-gradient(to left, rgb(255, 217, 251), transparent);
          }

          .slick-prev::before {
            content: url("/icons/prev-icon.svg");
            display: block;

          }
          .slick-next::before {
            content: url("/icons/next-icon.svg");
            display: block;

          }
          @media (max-width: 550px){
            .slick-prev,
            .slick-next {
              width: 4rem;
            }
          }
          @media (min-width: 400px){
            .service-container{
              margin-left: 24px;
            }
          }
        `}</style>
      </div>
      <div className="sm:py-20"></div>
    </div>
  );
};

export default PortfolioOne;
