// src/micros/utilities/dataUtilSoftware.js

export const Software = [
    { 
        
        title: "Electronic Health Record System",
        description: "We design and build secure and efficient systems to manage patients' records.",
    },
    {
        
        title: "Telemedicine Application",
        description: "We develop and deploy scalable platforms for remote consultations and healthcare services.",
    },
    {
        
        title: "Patient Portals",
        description: "We design, build, and develop secure portals for patients to access their health information.",
    },
    {
       
        title: "Appointment Scheduling System",
        description: "We implement easy-to-use systems for managing patient appointments.",
    },
];

export const financeSoftware = [
    { 
        
        title: "Banking and Financial Apps",
        description: "We develop secure and user-friendly applications for banking and financial services",
    },
    {
        
        title: "Fraud Detection",
        description: "We develop systems to detect and prevent fraudulent activities.",
    },
    
    {
       
        title: "Automated Trading Systems",
        description: "We build systems for automated trading to improve efficiency and accuracy",
    },
    {
        
        title: "Blockchain Solutions",
        description: "We implement blockchain technology to enhance security and transparency",
    },
];


    
     

export const retailSoftware = [
    { 
        
        title: "Mobile Shopping Apps",
        description: "We create user-friendly mobile apps to enhance shopping experience",
    },
    {
        
        title: "E-commerce Platforms",
        description: "We develop robust and scalable online stores tailored to your business needs.",
    },
    
    {
       
        title: "Customer Loyalty programs",
        description: "We design systems to reward loyal customers and boost retention.",
    },
    {
        
        title: "Inventory Management Systems",
        description: "We implement efficient solutions to implement stock and streamline operations.",
    },
];

export const technologySoftware = [
    { 
        
        title: "Saas Development",
        description: "We develop Software as a service(Saas) to offer on-demand applications",
    },
    {
        
        title: "Cloud Based Applications",
        description: "We create applications that leverage the power and flexibility of the cloud.",
    },
    
    {
       
        title: "API Integration",
        description: "We integrate various software systems through robust API's for seamless operations",
    },
    {
        
        title: "Enterprise Software Solutions",
        description: "We develop scalable software solutions for large organizations",
    },
];


    
     



    
     

