import React from "react";

const WhatWeDoCard = ({ icon, title, description }) => {
  const descriptionPoints = description.split(":").map((point, index) => (
    <li key={index} className="text-black-a font-poppins list-none">
      <span className="mr-2">-</span>
      {point.trim()}
    </li>
  ));

  return (
    <div className="bg-white p-4 rounded shadow max-w-xs w-full flex flex-col items-start transition-transform duration-200 ease-in-out" 
    style={{ transform: "scale(1)" }}
    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      <div className="flex items-center gap-2">
        <div className="bg-purple-dark-hover rounded-full flex items-center justify-center w-12 h-12 p-2">
          <img src={icon} alt={title} className="w-8 h-8 object-contain" />
        </div>
        <h4 className="text-purple-normal-active text-2xl font-quicksand-bold">
          {title}
        </h4>
      </div>
      <div className="py-6">
        <ul className="pl-0">{descriptionPoints}</ul>
      </div>
    </div>
  );
};

export default WhatWeDoCard;
