import React from "react";

function Description({ activeServiceTitle, activeServiceDescription }) {
  return (
    <div className="flex flex-col gap-4 md:gap-8 px-4 md:px-20 mt-4 md:mt-8">
      <hr className="w-full h-[2.5px] bg-gray-400" />
      <div className="flex flex-col sm:flex-row items-center sm:justify-center sm:gap-4">
        <div className="w-auto sm:w-[25vw] flex justify-center items-center">
          <p className="max-w-[59%] text-[#590050] font-poppins-semi-bold text-lg md:text-2xl text-center">
            {activeServiceTitle}
          </p>
        </div>
        <div className="sm:border-l-[2.5px] border-gray-400 w-full sm:w-[55vw] text-sm md:text-lg pt-8 pb-12 p-4 sm:pl-6 flex flex-col gap-6">
          {activeServiceDescription.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      </div>
      <hr className="w-full h-[2.5px] bg-gray-400" />
    </div>
  );
}

export default Description;
