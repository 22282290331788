import React, { useState } from "react";
import { SingleClick } from "../../../micros";
import DatePicker from "./Calendar";
import { Input } from "../../../micros/index";
import emailjs from "@emailjs/browser";

// import TimePicker from "react-time-picker";
// import TimeInput from "react-time-input";
import MessageTxt from "../../../major/messagesent/MessageTxt";
import { credentials } from "../../../../auth";
import { config } from "../../../../auth";
import TimeRangePicker from "./TimeRangePicker";
import PhoneInput from "react-phone-number-input";

const MeetingFormCompany = ({ onClose }) => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState({ start: "", end: "" });
  const [duration, setDuration] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("overflow-y-hidden");
  } else {
    document.body.classList.remove("overflow-y-hidden");
  }

  const validateEmail = (email) => {
    const gmailRegex = /@gmail\.com$/i;
    return !gmailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Work email cannot be a Gmail address.");
      return;
    }

    setError("");

    // Format date to YYYY-MM-DD
    const formattedDate = date.toISOString().split("T")[0];
    const formData = {
      email,
      phone_number: phoneNumber,
      company,
      position,
      message,
      date: formattedDate,

      time: time.start,

      duration,
    };
    try {
      const response = await fetch(
        `${config.baseURL}/careers/organization-bookings/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
          },
          body: JSON.stringify(formData),
        }
      );
      console.log("Here is your form data", formData);
      let da = JSON.stringify(formData);
      console.log("JSON", da);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);

      // Reset form fields
      // setCompany('');
      // setEmail('');
      // setPosition('');
      //setPhoneNumber("");
      // setMessage ('');
      // setDate('');
      // setTime('');
      // setDuration('');
      // toggleModal();
    } catch (error) {
      console.error("Error:", error);
    }

    // Send data with emailjs
    const serviceId = "service_i2t3886";
    const templateId = "template_kbgpofr";
    const publicKey = "ONqH6-Z93cZfem7Rj";

    const templateParams = {
      from_name: company,
      from_email: email,
      to_name: "wHTa Networks",
      message: `Company reservation by, Company Name: ${company}, Position: ${position}, Message: ${message}, Date: ${date}, Time: ${time}, Duration: ${duration}.`,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((res) => {
        console.log("Email sent successfully: ", res);
      })
      .catch((err) => {
        console.error("Error sending email, ", err);
      });

    setFormSubmit(!formSubmit);
  };

  return (
    <>
      {formSubmit ? (
        <div className="items-center justify-center flex">
          <MessageTxt onClose={onClose} />
        </div>
      ) : (
        <div className="max-w-screen-md mx-auto p-0 md:p-4 w-full rounded-lg bg-white relative">
          <div className="text-center pt-16 pb-12">
            <p className="xl:text-[3.125rem] lg:text-[3.125rem] md:text-[3.125rem] text-[1.8rem] font-poppins-semi-bold">
              Book a Meeting
            </p>
            <p className="xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.25rem] text-[1rem] text-gray-400 font-poppins-light">
              Let’s connect and work together, with just one click.
            </p>
          </div>
          <div
            className="absolute top-0 right-0 p-2 m-2 mt-2 mr-2 text-gray-600 cursor-pointer"
            onClick={onClose}
          >
            <img
              src="/cancel.svg"
              alt="Cancel Icon"
              className="w-5 h-5 md:w-7 md:h-7"
            />
          </div>
          <form onSubmit={handleSubmit} className="mt-4 ">
            <div className="w-full">
              <div className="pb-4">
                <Input
                  type="email"
                  placeholder="work email"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={email}
                  onChange={setEmail}
                  copyPaste={true}
                />
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
              </div>
              <div className="pb-4">
                <PhoneInput
                  international
                  defaultCountry="KE"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className="border py-3 px-4 w-full rounded focus:outline-none "
                  style={{ backgroundColor: "#FFF5FE" }}
                  required
                />
              </div>
              <div className="pb-4">
                <Input
                  type="text"
                  placeholder="company"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={company}
                  onChange={setCompany}
                  copyPaste={false}
                />
              </div>
              <div className="pb-4">
                <Input
                  type="text"
                  placeholder="Position"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={position}
                  onChange={setPosition}
                  copyPaste={false}
                />
              </div>
              <div className="pb-4">
                <Input
                  type="text"
                  placeholder="Drop a message"
                  inputClass="1"
                  isRequired={true}
                  asteriskClass="1"
                  value={message}
                  onChange={setMessage}
                  copyPaste={false}
                />
              </div>
            </div>
            <div className="flex flex-row-reverse xl:gap-14 lg:gap-14 md:gap-10 gap-2 w-full">
              <div className="icon-input mt-4 w-1/2  flex items-center text-xl">
                <input
                  type="text"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="w-full text-sm md:text-xl p-2 border rounded bg-purple-f focus:outline-none focus:border-blue-500 pl-7"
                  placeholder="Duration in Minutes"
                  style={{
                    backgroundImage: 'url("/duration.svg")',
                    backgroundSize: "1.2rem",
                    backgroundPosition: "left center  ",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>

              <div className="icon-input mt-4 w-1/2 flex items-center justify-between text-xl">
                <TimeRangePicker setDuration={setDuration} setTime={setTime} />
              </div>
            </div>

            <div className="flex justify-center  xl:gap-14 lg:gap-14 md:gap-10 gap-2  w-full">
              <div className="icon-input mt-4 w-1/2 p-2 flex items-center  md:px-0 px-4">
                <DatePicker onDateChange={handleDateChange} />
              </div>
            </div>
            <div className="flex items-center justify-center pt-10 pb-10">
              <SingleClick
                buttonClass="10"
                buttonText="Book"
                buttonType="submit"
                onClick={handleSubmit}
              />
            </div>
            {modal && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                <div
                  onClick={toggleModal}
                  className="flex flex-col items-center justify-center"
                >
                  <div
                    className="bg-white p-8 rounded-lg shadow-lg"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex justify-end">
                      <button
                        onClick={toggleModal}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <h2 className="text-xl font-bold mb-4">
                      Company Meeting Booking
                    </h2>
                    <p>
                      Company Name: <strong>{company}</strong>
                    </p>
                    <p>
                      Position: <strong>{position}</strong>
                    </p>
                    <p>
                      Message: <strong>{message}</strong>
                    </p>
                    <p>
                      Date: <strong>{date.toLocaleDateString()}</strong>
                    </p>
                    <p>
                      Time:{" "}
                      <strong>
                        From {time.start} to {time.end}
                      </strong>
                    </p>
                    <p>
                      Duration: <strong>{duration} minutes</strong>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default MeetingFormCompany;
