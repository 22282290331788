import React from "react";
import { HealthcareAdvantages } from '../../../../micros/utilities/dataUtilHealthcare';

function AdvantagesComponent() {
    return (
        <div className='w-full relative h-auto md:h-auto md:min-h-[100vh] max-w-8xl overflow-x-hidden py-32 p-2' style={{ backgroundColor: "rgba(242, 225, 241, 0.7)" }}>
            <div className='flex flex-col md:flex-row md:m-6 m-0 gap-6 items-center justify-between md:px-14 px-5'>
                <div className='w-full flex flex-col text-purple-darker items-center justify-center mb-8'>
                    <p className='whitespace-pre-line font-poppins-semi-bold md:text-center md:text-[40.89px] text-2xl md:w-[936px] w-auto' >
                        Advantages of working with wHTa Networks
                    </p>
                </div>
            </div>
            <div className='flex flex-wrap gap-6 overflow-x-auto justify-center items-center'>
                {HealthcareAdvantages.map((advantage, index) => (
                    <div 
                        key={index} 
                        className={`advantage-item p-4 rounded-3xl shadow-md flex flex-col items-center ${index === HealthcareAdvantages.length - 1 ? 'last-card' : ''}`} 
                        style={{ 
                            backgroundColor: "white", 
                            width: '346px', 
                            height: '455.54px', 
                            boxSizing: 'border-box',
                            margin: '23px',
                            position: 'relative', 
                            transition: 'transform 0.3s, z-index 0.3s',
                        }}
                    >
                        <div className="h-1/2">
                            <img src={advantage.image} alt={advantage.title} className="h-auto w-auto pt-10" />
                        </div>
                        <h2 className='text-2xl font-semibold text-center mb-2 py-3' style={{ color: "rgba(202, 0, 182, 1)", fontSize: '2rem' }}>
                            {advantage.title}
                        </h2>
                        <p className='text-md text-center my-6' style={{ width: '283.29px', height: '67.31px', fontSize: '1rem', fontWeight: 'semibold' }}>
                            {advantage.description}
                        </p>
                    </div>
                ))}
            </div>
            <style jsx>{`
                .advantage-item:hover {
                    z-index: 10;
                    transform: scale(1.05);
                }

                .last-card {
                    align-self: flex-start;
                    margin-left: 0 !important;
                }
            `}</style>
        </div>
    );
}

export default AdvantagesComponent;







