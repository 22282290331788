import React from "react";

function WhyChooseUs(whyus, index) {
  return (
    <div
      className="bg-white-a rounded-3xl p-4 min-w-[18rem] max-w-[18rem] min-h-[24rem] max-h-[24rem] sm:min-w-[22rem] sm:max-w-[22rem] sm:min-h-[28rem] sm:max-h-[28rem] flex items-center justify-center shadow-xl"
      key={index}
    >
      <div className="h-5/6 flex flex-col justify-between items-center gap-4 xl:gap-10">
        <div className="bg-purple-dark-hover rounded-full flex items-center justify-center min-w-40 max-w-40 min-h-40 max-h-40 p-2 sm:p-4">
          <img src={whyus.icon} alt={whyus.title} className="w-3/5 h-3/5" />
        </div>
        <h3 className="text-purple-normal-active text-2xl  text-center font-quicksand-bold">
          {whyus.title}
        </h3>
        <p className="text-black-a text-center font-poppins">
          {whyus.description}
        </p>
      </div>
    </div>
  );
}

export default WhyChooseUs;
