import React from 'react'
import { ServiceCard } from '../../../micros/index'

function ProductDesignService() {
    const services = [
        {
            title: 'Product Ideation',
            description: "Transforming your ideas into viable product concepts through brainstorming, research, and prototyping"
        },
        {
            title: 'User Research',
            description: "'We conduct user research to understand your target audience's needs, behaviors, and preferences, then create user personas to inform the design process.'"
        },
        {
            title: 'Wireframing and Prototyping',
            description: "Creating wireframes and interactive prototypes to visualize the user flow and functionality of your product before development."
        },
        {
            title:'UI/UX Design',
            description: "Designing intuitive and visually appealing user interfaces (UI) and user experiences (UX) that enhance usability and engagement."
        },
        {
            title:"Design Validation and Testing",
            description: "Conducting usability testing and gathering feedback from real users to validate designs and identify areas for improvement."
        }


    ]
  return (
    <>
        <div id="learn_more"className=' text-center flex flex-col justify-center items-center bg-purple-f  pb-[4rem] sm:pb-[6rem] xl:pb-[10rem]'>
            <h1 className='font-poppins-bold text-3xl sm:text-4xl xl:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>Product Design Services</h1>
            <div className='w-[20rem] xl:w-[35rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[8.5rem]'></div>
            <div className='flex justify-center items-center px-4 '>
                <div className='flex gap-[3rem] lg:gap-[5rem] items-center justify-center md:flex-row flex-col flex-wrap '>
                    {services.map((service, index) => (
                       <ServiceCard {...service} key={index} cardClass='1' tittleClass='2' />

                        )
                    )}
                </div>
         
            </div>
            
        </div>
    </>
  )
}

export default ProductDesignService